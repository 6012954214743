import {
    app, BaseComponent, read, update, Component, readFlavor, mergeStyles, matchUrl, media, readTextString, readStyle
} from '@onejs-dev/core';
import {
     View, Icon, Modal, Input, Text, Table, readText
} from '@onejs-dev/components';


import {CodeDisplay, Navbar, CodeSandbox} from '@onejs-dev/procomponents';

//Icons
// import {icons} from '@onejs-dev/icons';
import star from '../../assets/icons/star.svg';
import heart from '../../assets/icons/heart.svg';
import chatbubble from '../../assets/icons/chatbubble.svg';
import musicalNotes from '../../assets/icons/musical-notes.svg';
import paw from '../../assets/icons/paw.svg';
import leaf from '../../assets/icons/leaf.svg';

export const Docs = () => [
		View({
            animation: {visible: ['fade-in', 'fade-out']}, visible: read('doc') === '',
            content: {h: 'stretch', v: 'top', direction: 'column', gap: 20},
            self: {align: 'center'}, style: readStyle('pageContent')
        })([

            Text({style: readStyle('unitTitle'), flavor: readFlavor('header')})('Components'),
            View({content: {wrap: true, h: 'center', gap: 10}, style: {padding: 20}})([                
                View({url: '/docs/View', content: {h: 'stretch', v: 'space', direction: 'column'}, flavor: readFlavor('outline', 'lightBackground'), style: readStyle('card', 'pointer')})([                
                    View({content: {h: 'center', v: 'center'}, style: readStyle('cardTitle')})(Text({flavor: readFlavor('section')})('View')),
                    View({content: {h: 'center', v: 'center', direction: 'column'}, self: {expand: 1}})([                                 
                        View({content: {h: 'center', gap: 5, wrap: true}, flavor: readFlavor('outline'), style: {
                            padding: 10,
                            width: 150,
                            height: 100,                    
                        }})([
                            View({style: {width: 30, height: 30}, flavor: readFlavor('primaryBackground')})(),
                            View({style: {width: 30, height: 30}, flavor: readFlavor('primaryBackground')})(),
                            View({style: {width: 30, height: 30}, flavor: readFlavor('primaryBackground')})(),
                            View({style: {width: 30, height: 30}, flavor: readFlavor('primaryBackground')})(),
                            View({style: {width: 30, height: 30}, flavor: readFlavor('primaryBackground')})(),
                            View({style: {width: 30, height: 30}, flavor: readFlavor('primaryBackground')})(),
                            View({style: {width: 30, height: 30}, flavor: readFlavor('primaryBackground')})(),
                            View({style: {width: 30, height: 30}, flavor: readFlavor('primaryBackground')})(),
                            View({style: {width: 30, height: 30}, flavor: readFlavor('primaryBackground')})(),
                            View({style: {width: 30, height: 30}, flavor: readFlavor('primaryBackground')})(),
                        ]),
                    ])
                ]),
                View({url: '/docs/Text', content: {h: 'stretch', v: 'space', direction: 'column'}, flavor: readFlavor('outline'), style: readStyle('card', 'pointer')})([                
                    View({content: {h: 'center', v: 'center'}, style: readStyle('cardTitle')})(Text({flavor: readFlavor('section')})('Text')),
                    View({content: {h: 'center', v: 'center', direction: 'column'}, self: {expand: 1}})([
                        Text({flavor: readFlavor('header')})('Header'),
                        Text({flavor: readFlavor('section')})('Section'),
                        Text({flavor: readFlavor('subsection')})('Subsection'),
                        Text({flavor: readFlavor('regular')})('Regular Text')
                    ]),
                ]),
                View({url: '/docs/Input', content: {h: 'stretch', v: 'space', direction: 'column'}, flavor: readFlavor('outline'), style: readStyle('card', 'pointer')})([                
                    View({content: {h: 'center', v: 'center'}, style: readStyle('cardTitle')})(Text({flavor: readFlavor('section')})('Input')),
                    View({content: {wrap: true, h: 'center', v: 'center', gap: 10}, self: {expand: 1}, style: {padding: 10}})([               
                        Input({type: 'button', title: 'Button', flavor: readFlavor('light', 'primaryBackground')}),
                        Input({type: 'switch', value: true}),
                        Input({type: 'list', options: ['Option 1', 'Option 2', 'Option 3']}),
                        Input({type: 'range', min: 0, max: 10, value: 3}),
                        Input({type: 'checkbox', title: 'Select option', value: true})
                    ])
                ]),
                View({url: '/docs/Icon', content: {h: 'stretch', v: 'space', direction: 'column'}, flavor: readFlavor('outline'), style: readStyle('card', 'pointer')})([                
                    View({content: {h: 'center', v: 'center'}, style: readStyle('cardTitle')})(Text({flavor: readFlavor('section')})('Icon')),
                    View({content: {wrap: true, h: 'center', v: 'center', gap: 10}, self: {expand: 1}, style: {padding: 10}})([
                        View({content: {wrap: true, h: 'center', v: 'center', gap: 10}})([
                            Icon({icon: star, flavor: readFlavor('warn'), size: 50}),
                            Icon({icon: heart, flavor: readFlavor('reject'), size: 70}),
                            Icon({icon: chatbubble, flavor: readFlavor('light'), size: 30})
                        ]),
                        View({content: {wrap: true, h: 'center', v: 'center', gap: 10}})([
                            Icon({icon: musicalNotes, flavor: readFlavor('primary'), size: 60}),                                        
                            Icon({icon: paw, flavor: readFlavor('dark'), size: 30}),
                            Icon({icon: leaf, flavor: readFlavor('accept'), size: 50})
                        ])                        
                    ])
                ]),  
                View({url: '/docs/StandardComponents', content: {h: 'stretch', v: 'space', direction: 'column'}, flavor: readFlavor('outline'), style: readStyle('card', 'pointer')})([                
                    View({content: {h: 'center', v: 'center'}, style: readStyle('cardTitle')})(Text({flavor: readFlavor('section')})('Standard')),
                    View({content: {wrap: true, h: 'center', v: 'center', gap: 10}, self: {expand: 1}, style: {padding: 10}})([  
                        Text({flavor: readFlavor('subsection')})('HTML'),
                        Text()('<img>, <p>, <input>, <div>, ...'),
                        Text({flavor: readFlavor('subsection')})('React Native'),
                        Text()('Image, Text, TextInput, View, ...'),
                    ])
                ]),              
                // View({url: '/docs/modal', content: {h: 'stretch', v: 'space', direction: 'column'}, flavor: readFlavor('outline'), style: readStyle('card', 'pointer')})([                
                //     View({content: {h: 'center', v: 'center'}, style: readStyle('cardTitle')})(Text({flavor: readFlavor('section')})('Modal')),
                //     View({content: {h: 'center', v: 'center', direction: 'column'}, self: {expand: 1}})([             
                //         View({content: {h: 'stretch', direction: 'column', gap: 10}, flavor: readFlavor('outline'), style: {
                //             padding: 10,
                //             width: 150,
                //             height: 100,                    
                //             boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',                
                //         }})([
                //             Icon({icon: icons['close-circle'], flavor: readFlavor('neutral')}), 
                //             View({content: {h: 'center'}})(Text({flavor: readFlavor('subsection')})('Text content'))
                //         ])
                //     ])
                // ]),
                
            ]),
            Text({style: readStyle('unitTitle'), flavor: readFlavor('header')})('Functions'),
            View({content: {wrap: true, h: 'center', gap: 10}, style: {padding: 20}})([
                View({content: {h: 'stretch', v: 'space', direction: 'column'}, flavor: readFlavor('outline'), style: readStyle('card')})([                
                    View({content: {h: 'center', v: 'center'}, style: readStyle('cardTitle')})(Text({flavor: readFlavor('section')})('State')),
                    View({content: {wrap: true, h: 'center', v: 'center', gap: 5}, self: {expand: 1}, style: {padding: 5}})([               
                        Input({type: 'button', title: 'read', url: '/docs/read', style: {width: 200}}),
                        Input({type: 'button', title: 'update', url: '/docs/update', style: {width: 200}}),
                        Input({type: 'button', title: 'add', url: '/docs/add', style: {width: 200}}),
                        Input({type: 'button', title: 'remove', url: '/docs/remove', style: {width: 200}}),
                    ])
                ]),
                View({content: {h: 'stretch', v: 'space', direction: 'column'}, flavor: readFlavor('outline'), style: readStyle('card')})([                
                    View({content: {h: 'center', v: 'center'}, style: readStyle('cardTitle')})(Text({flavor: readFlavor('section')})('App')),
                    View({content: {wrap: true, h: 'center', v: 'center', gap: 5}, self: {expand: 1}, style: {padding: 5}})([               
                        Input({type: 'button', title: 'BaseComponent', url: '/docs/BaseComponent', style: {width: 200}}),
                        Input({type: 'button', title: 'Component', url: '/docs/Component', style: {width: 200}}),
                        Input({type: 'button', title: 'app', url: '/docs/app', style: {width: 200}}),
                    ])
                ]),                
                View({content: {h: 'stretch', v: 'space', direction: 'column'}, flavor: readFlavor('outline'), style: readStyle('card')})([                
                    View({content: {h: 'center', v: 'center'}, style: readStyle('cardTitle')})(Text({flavor: readFlavor('section')})('Theme')),
                    View({content: {wrap: true, h: 'center', v: 'center', gap: 5}, self: {expand: 1}, style: {padding: 5}})([               
                        Input({type: 'button', title: 'readFlavor', url: '/docs/readFlavor', style: {width: 200}}),
                        Input({type: 'button', title: 'readStyle', url: '/docs/readStyle', style: {width: 200}}),
                        // Input({type: 'button', title: 'updateFlavor', url: '/docs/updateFlavor', style: {width: 200}}),
                        Input({type: 'button', title: 'mergeStyles', url: '/docs/mergeStyles', style: {width: 200}}),
                    ])
                ]),
            ]),
        ]),
        View({            
            animation: {visible: ['fade-in', 'fade-out']}, visible: read('doc') === 'StandardComponents',
            content: {h: 'stretch', v: 'top', direction: 'column', gap: 20},
            self: {align: 'center'}, style: readStyle('pageContent')
        })([ 
            Text({flavor: readFlavor('section'), style: readStyle('unitTitle')})('Standard Components'),
            readText('StandardComponentsH1P1')({}),
            readText('webComponents')({}),
            readText('webComponentsP1')({}),
            // AutocompleteInput({options: htmlTags, value: read('htmlTag'), onChange: update('htmlTag')}),
            Table({columns: 4, style: {maxHeight: 500}})([
                readText('tag')(), readText('function')(), readText('description')(), readText('hasChildren')(),
                Text()('<a>'), Text({code: ['HtmlA']})('HtmlA'), Text({link: [{text:'Link', target:'_blank', url:'https://www.w3schools.com/tags/tag_a.asp'}]})('Link'), readText('yes')(),
                Text()('<abbr>'), Text({code: ['HtmlAbbr']})('HtmlAbbr'), Text({link: [{text:'Link', target:'_blank', url:'https://www.w3schools.com/tags/tag_abbr.asp'}]})('Link'), readText('yes')(),
                Text()('<address>'), Text({code: ['HtmlAddress']})('HtmlAddress'), Text({link: [{text:'Link', target:'_blank', url:'https://www.w3schools.com/tags/tag_address.asp'}]})('Link'), readText('yes')(),
                Text()('<area>'), Text({code: ['HtmlArea']})('HtmlArea'), Text({link: [{text:'Link', target:'_blank', url:'https://www.w3schools.com/tags/tag_area.asp'}]})('Link'), readText('no')(),
                Text()('<article>'), Text({code: ['HtmlArticle']})('HtmlArticle'), Text({link: [{text:'Link', target:'_blank', url:'https://www.w3schools.com/tags/tag_article.asp'}]})('Link'), readText('yes')(),
                Text()('<aside>'), Text({code: ['HtmlAside']})('HtmlAside'), Text({link: [{text:'Link', target:'_blank', url:'https://www.w3schools.com/tags/tag_aside.asp'}]})('Link'), readText('yes')(),
                Text()('<audio>'), Text({code: ['HtmlAudio']})('HtmlAudio'), Text({link: [{text:'Link', target:'_blank', url:'https://www.w3schools.com/tags/tag_audio.asp'}]})('Link'), readText('yes')(),
                Text()('<b>'), Text({code: ['HtmlB']})('HtmlB'), Text({link: [{text:'Link', target:'_blank', url:'https://www.w3schools.com/tags/tag_b.asp'}]})('Link'), readText('yes')(),
                Text()('<base>'), Text({code: ['HtmlBase']})('HtmlBase'), Text({link: [{text:'Link', target:'_blank', url:'https://www.w3schools.com/tags/tag_base.asp'}]})('Link'), readText('no')(),
                Text()('<bdi>'), Text({code: ['HtmlBdi']})('HtmlBdi'), Text({link: [{text:'Link', target:'_blank', url:'https://www.w3schools.com/tags/tag_bdi.asp'}]})('Link'), readText('yes')(),
                Text()('<bdo>'), Text({code: ['HtmlBdo']})('HtmlBdo'), Text({link: [{text:'Link', target:'_blank', url:'https://www.w3schools.com/tags/tag_bdo.asp'}]})('Link'), readText('yes')(),
                Text()('<blockquote>'), Text({code: ['HtmlBlockquote']})('HtmlBlockquote'), Text({link: [{text:'Link', target:'_blank', url:'https://www.w3schools.com/tags/tag_blockquote.asp'}]})('Link'), readText('yes')(),
                Text()('<body>'), Text({code: ['HtmlBody']})('HtmlBody'), Text({link: [{text:'Link', target:'_blank', url:'https://www.w3schools.com/tags/tag_body.asp'}]})('Link'), readText('yes')(),
                Text()('<br>'), Text({code: ['HtmlBr']})('HtmlBr'), Text({link: [{text:'Link', target:'_blank', url:'https://www.w3schools.com/tags/tag_br.asp'}]})('Link'), readText('no')(),
                Text()('<button>'), Text({code: ['HtmlButton']})('HtmlButton'), Text({link: [{text:'Link', target:'_blank', url:'https://www.w3schools.com/tags/tag_button.asp'}]})('Link'), readText('yes')(),
                Text()('<canvas>'), Text({code: ['HtmlCanvas']})('HtmlCanvas'), Text({link: [{text:'Link', target:'_blank', url:'https://www.w3schools.com/tags/tag_canvas.asp'}]})('Link'), readText('yes')(),
                Text()('<caption>'), Text({code: ['HtmlCaption']})('HtmlCaption'), Text({link: [{text:'Link', target:'_blank', url:'https://www.w3schools.com/tags/tag_caption.asp'}]})('Link'), readText('yes')(),
                Text()('<cite>'), Text({code: ['HtmlCite']})('HtmlCite'), Text({link: [{text:'Link', target:'_blank', url:'https://www.w3schools.com/tags/tag_cite.asp'}]})('Link'), readText('yes')(),
                Text()('<code>'), Text({code: ['HtmlCode']})('HtmlCode'), Text({link: [{text:'Link', target:'_blank', url:'https://www.w3schools.com/tags/tag_code.asp'}]})('Link'), readText('yes')(),
                Text()('<col>'), Text({code: ['HtmlCol']})('HtmlCol'), Text({link: [{text:'Link', target:'_blank', url:'https://www.w3schools.com/tags/tag_col.asp'}]})('Link'), readText('no')(),
                Text()('<colgroup>'), Text({code: ['HtmlColgroup']})('HtmlColgroup'), Text({link: [{text:'Link', target:'_blank', url:'https://www.w3schools.com/tags/tag_colgroup.asp'}]})('Link'), readText('no')(),
                Text()('<data>'), Text({code: ['HtmlData']})('HtmlData'), Text({link: [{text:'Link', target:'_blank', url:'https://www.w3schools.com/tags/tag_data.asp'}]})('Link'), readText('yes')(),
                Text()('<datalist>'), Text({code: ['HtmlDatalist']})('HtmlDatalist'), Text({link: [{text:'Link', target:'_blank', url:'https://www.w3schools.com/tags/tag_datalist.asp'}]})('Link'), readText('yes')(),
                Text()('<dd>'), Text({code: ['HtmlDd']})('HtmlDd'), Text({link: [{text:'Link', target:'_blank', url:'https://www.w3schools.com/tags/tag_dd.asp'}]})('Link'), readText('yes')(),
                Text()('<del>'), Text({code: ['HtmlDel']})('HtmlDel'), Text({link: [{text:'Link', target:'_blank', url:'https://www.w3schools.com/tags/tag_del.asp'}]})('Link'), readText('yes')(),
                Text()('<details>'), Text({code: ['HtmlDetails']})('HtmlDetails'), Text({link: [{text:'Link', target:'_blank', url:'https://www.w3schools.com/tags/tag_details.asp'}]})('Link'), readText('yes')(),
                Text()('<dfn>'), Text({code: ['HtmlDfn']})('HtmlDfn'), Text({link: [{text:'Link', target:'_blank', url:'https://www.w3schools.com/tags/tag_dfn.asp'}]})('Link'), readText('yes')(),
                Text()('<dialog>'), Text({code: ['HtmlDialog']})('HtmlDialog'), Text({link: [{text:'Link', target:'_blank', url:'https://www.w3schools.com/tags/tag_dialog.asp'}]})('Link'), readText('yes')(),
                Text()('<div>'), Text({code: ['HtmlDiv']})('HtmlDiv'), Text({link: [{text:'Link', target:'_blank', url:'https://www.w3schools.com/tags/tag_div.asp'}]})('Link'), readText('yes')(),
                Text()('<dl>'), Text({code: ['HtmlDl']})('HtmlDl'), Text({link: [{text:'Link', target:'_blank', url:'https://www.w3schools.com/tags/tag_dl.asp'}]})('Link'), readText('yes')(),
                Text()('<dt>'), Text({code: ['HtmlDt']})('HtmlDt'), Text({link: [{text:'Link', target:'_blank', url:'https://www.w3schools.com/tags/tag_dt.asp'}]})('Link'), readText('yes')(),
                Text()('<em>'), Text({code: ['HtmlEm']})('HtmlEm'), Text({link: [{text:'Link', target:'_blank', url:'https://www.w3schools.com/tags/tag_em.asp'}]})('Link'), readText('yes')(),
                Text()('<embed>'), Text({code: ['HtmlEmbed']})('HtmlEmbed'), Text({link: [{text:'Link', target:'_blank', url:'https://www.w3schools.com/tags/tag_embed.asp'}]})('Link'), readText('no')(),
                Text()('<fieldset>'), Text({code: ['HtmlFieldset']})('HtmlFieldset'), Text({link: [{text:'Link', target:'_blank', url:'https://www.w3schools.com/tags/tag_fieldset.asp'}]})('Link'), readText('yes')(),
                Text()('<figcaption>'), Text({code: ['HtmlFigcaption']})('HtmlFigcaption'), Text({link: [{text:'Link', target:'_blank', url:'https://www.w3schools.com/tags/tag_figcaption.asp'}]})('Link'), readText('yes')(),
                Text()('<figure>'), Text({code: ['HtmlFigure']})('HtmlFigure'), Text({link: [{text:'Link', target:'_blank', url:'https://www.w3schools.com/tags/tag_figure.asp'}]})('Link'), readText('yes')(),
                Text()('<footer>'), Text({code: ['HtmlFooter']})('HtmlFooter'), Text({link: [{text:'Link', target:'_blank', url:'https://www.w3schools.com/tags/tag_footer.asp'}]})('Link'), readText('yes')(),
                Text()('<form>'), Text({code: ['HtmlForm']})('HtmlForm'), Text({link: [{text:'Link', target:'_blank', url:'https://www.w3schools.com/tags/tag_form.asp'}]})('Link'), readText('yes')(),
                Text()('<h1>'), Text({code: ['HtmlH1']})('HtmlH1'), Text({link: [{text:'Link', target:'_blank', url:'https://www.w3schools.com/tags/tag_h1.asp'}]})('Link'), readText('yes')(),
                Text()('<h2>'), Text({code: ['HtmlH2']})('HtmlH2'), Text({link: [{text:'Link', target:'_blank', url:'https://www.w3schools.com/tags/tag_h2.asp'}]})('Link'), readText('yes')(),
                Text()('<h3>'), Text({code: ['HtmlH3']})('HtmlH3'), Text({link: [{text:'Link', target:'_blank', url:'https://www.w3schools.com/tags/tag_h3.asp'}]})('Link'), readText('yes')(),
                Text()('<h4>'), Text({code: ['HtmlH4']})('HtmlH4'), Text({link: [{text:'Link', target:'_blank', url:'https://www.w3schools.com/tags/tag_h4.asp'}]})('Link'), readText('yes')(),
                Text()('<h5>'), Text({code: ['HtmlH5']})('HtmlH5'), Text({link: [{text:'Link', target:'_blank', url:'https://www.w3schools.com/tags/tag_h5.asp'}]})('Link'), readText('yes')(),
                Text()('<h6>'), Text({code: ['HtmlH6']})('HtmlH6'), Text({link: [{text:'Link', target:'_blank', url:'https://www.w3schools.com/tags/tag_h6.asp'}]})('Link'), readText('yes')(),
                Text()('<head>'), Text({code: ['HtmlHead']})('HtmlHead'), Text({link: [{text:'Link', target:'_blank', url:'https://www.w3schools.com/tags/tag_head.asp'}]})('Link'), readText('yes')(),
                Text()('<header>'), Text({code: ['HtmlHeader']})('HtmlHeader'), Text({link: [{text:'Link', target:'_blank', url:'https://www.w3schools.com/tags/tag_header.asp'}]})('Link'), readText('yes')(),
                Text()('<hr>'), Text({code: ['HtmlHr']})('HtmlHr'), Text({link: [{text:'Link', target:'_blank', url:'https://www.w3schools.com/tags/tag_hr.asp'}]})('Link'), readText('no')(),
                Text()('<html>'), Text({code: ['HtmlHtml']})('HtmlHtml'), Text({link: [{text:'Link', target:'_blank', url:'https://www.w3schools.com/tags/tag_html.asp'}]})('Link'), readText('yes')(),
                Text()('<i>'), Text({code: ['HtmlI']})('HtmlI'), Text({link: [{text:'Link', target:'_blank', url:'https://www.w3schools.com/tags/tag_i.asp'}]})('Link'), readText('yes')(),
                Text()('<iframe>'), Text({code: ['HtmlIframe']})('HtmlIframe'), Text({link: [{text:'Link', target:'_blank', url:'https://www.w3schools.com/tags/tag_iframe.asp'}]})('Link'), readText('yes')(),
                Text()('<img>'), Text({code: ['HtmlImg']})('HtmlImg'), Text({link: [{text:'Link', target:'_blank', url:'https://www.w3schools.com/tags/tag_img.asp'}]})('Link'), readText('no')(),
                Text()('<input>'), Text({code: ['HtmlInput']})('HtmlInput'), Text({link: [{text:'Link', target:'_blank', url:'https://www.w3schools.com/tags/tag_input.asp'}]})('Link'), readText('no')(),
                Text()('<ins>'), Text({code: ['HtmlIns']})('HtmlIns'), Text({link: [{text:'Link', target:'_blank', url:'https://www.w3schools.com/tags/tag_ins.asp'}]})('Link'), readText('yes')(),
                Text()('<kbd>'), Text({code: ['HtmlKbd']})('HtmlKbd'), Text({link: [{text:'Link', target:'_blank', url:'https://www.w3schools.com/tags/tag_kbd.asp'}]})('Link'), readText('yes')(),
                Text()('<label>'), Text({code: ['HtmlLabel']})('HtmlLabel'), Text({link: [{text:'Link', target:'_blank', url:'https://www.w3schools.com/tags/tag_label.asp'}]})('Link'), readText('yes')(),
                Text()('<legend>'), Text({code: ['HtmlLegend']})('HtmlLegend'), Text({link: [{text:'Link', target:'_blank', url:'https://www.w3schools.com/tags/tag_legend.asp'}]})('Link'), readText('yes')(),
                Text()('<li>'), Text({code: ['HtmlLi']})('HtmlLi'), Text({link: [{text:'Link', target:'_blank', url:'https://www.w3schools.com/tags/tag_li.asp'}]})('Link'), readText('yes')(),
                Text()('<link>'), Text({code: ['HtmlLink']})('HtmlLink'), Text({link: [{text:'Link', target:'_blank', url:'https://www.w3schools.com/tags/tag_link.asp'}]})('Link'), readText('no')(),
                Text()('<main>'), Text({code: ['HtmlMain']})('HtmlMain'), Text({link: [{text:'Link', target:'_blank', url:'https://www.w3schools.com/tags/tag_main.asp'}]})('Link'), readText('yes')(),
                Text()('<map>'), Text({code: ['HtmlMap']})('HtmlMap'), Text({link: [{text:'Link', target:'_blank', url:'https://www.w3schools.com/tags/tag_map.asp'}]})('Link'), readText('yes')(),
                Text()('<mark>'), Text({code: ['HtmlMark']})('HtmlMark'), Text({link: [{text:'Link', target:'_blank', url:'https://www.w3schools.com/tags/tag_mark.asp'}]})('Link'), readText('yes')(),
                Text()('<meta>'), Text({code: ['HtmlMeta']})('HtmlMeta'), Text({link: [{text:'Link', target:'_blank', url:'https://www.w3schools.com/tags/tag_meta.asp'}]})('Link'), readText('no')(),
                Text()('<meter>'), Text({code: ['HtmlMeter']})('HtmlMeter'), Text({link: [{text:'Link', target:'_blank', url:'https://www.w3schools.com/tags/tag_meter.asp'}]})('Link'), readText('yes')(),
                Text()('<nav>'), Text({code: ['HtmlNav']})('HtmlNav'), Text({link: [{text:'Link', target:'_blank', url:'https://www.w3schools.com/tags/tag_nav.asp'}]})('Link'), readText('yes')(),
                Text()('<noscript>'), Text({code: ['HtmlNoscript']})('HtmlNoscript'), Text({link: [{text:'Link', target:'_blank', url:'https://www.w3schools.com/tags/tag_noscript.asp'}]})('Link'), readText('yes')(),
                Text()('<object>'), Text({code: ['HtmlObject']})('HtmlObject'), Text({link: [{text:'Link', target:'_blank', url:'https://www.w3schools.com/tags/tag_object.asp'}]})('Link'), readText('yes')(),
                Text()('<ol>'), Text({code: ['HtmlOl']})('HtmlOl'), Text({link: [{text:'Link', target:'_blank', url:'https://www.w3schools.com/tags/tag_ol.asp'}]})('Link'), readText('yes')(),
                Text()('<optgroup>'), Text({code: ['HtmlOptgroup']})('HtmlOptgroup'), Text({link: [{text:'Link', target:'_blank', url:'https://www.w3schools.com/tags/tag_optgroup.asp'}]})('Link'), readText('yes')(),
                Text()('<option>'), Text({code: ['HtmlOption']})('HtmlOption'), Text({link: [{text:'Link', target:'_blank', url:'https://www.w3schools.com/tags/tag_option.asp'}]})('Link'), readText('yes')(),
                Text()('<output>'), Text({code: ['HtmlOutput']})('HtmlOutput'), Text({link: [{text:'Link', target:'_blank', url:'https://www.w3schools.com/tags/tag_output.asp'}]})('Link'), readText('yes')(),
                Text()('<p>'), Text({code: ['HtmlP']})('HtmlP'), Text({link: [{text:'Link', target:'_blank', url:'https://www.w3schools.com/tags/tag_p.asp'}]})('Link'), readText('yes')(),
                Text()('<param>'), Text({code: ['HtmlParam']})('HtmlParam'), Text({link: [{text:'Link', target:'_blank', url:'https://www.w3schools.com/tags/tag_param.asp'}]})('Link'), readText('no')(),
                Text()('<picture>'), Text({code: ['HtmlPicture']})('HtmlPicture'), Text({link: [{text:'Link', target:'_blank', url:'https://www.w3schools.com/tags/tag_picture.asp'}]})('Link'), readText('yes')(),
                Text()('<pre>'), Text({code: ['HtmlPre']})('HtmlPre'), Text({link: [{text:'Link', target:'_blank', url:'https://www.w3schools.com/tags/tag_pre.asp'}]})('Link'), readText('yes')(),
                Text()('<progress>'), Text({code: ['HtmlProgress']})('HtmlProgress'), Text({link: [{text:'Link', target:'_blank', url:'https://www.w3schools.com/tags/tag_progress.asp'}]})('Link'), readText('yes')(),
                Text()('<q>'), Text({code: ['HtmlQ']})('HtmlQ'), Text({link: [{text:'Link', target:'_blank', url:'https://www.w3schools.com/tags/tag_q.asp'}]})('Link'), readText('yes')(),
                Text()('<rp>'), Text({code: ['HtmlRp']})('HtmlRp'), Text({link: [{text:'Link', target:'_blank', url:'https://www.w3schools.com/tags/tag_rp.asp'}]})('Link'), readText('yes')(),
                Text()('<rt>'), Text({code: ['HtmlRt']})('HtmlRt'), Text({link: [{text:'Link', target:'_blank', url:'https://www.w3schools.com/tags/tag_rt.asp'}]})('Link'), readText('yes')(),
                Text()('<ruby>'), Text({code: ['HtmlRuby']})('HtmlRuby'), Text({link: [{text:'Link', target:'_blank', url:'https://www.w3schools.com/tags/tag_ruby.asp'}]})('Link'), readText('yes')(),
                Text()('<s>'), Text({code: ['HtmlS']})('HtmlS'), Text({link: [{text:'Link', target:'_blank', url:'https://www.w3schools.com/tags/tag_s.asp'}]})('Link'), readText('yes')(),
                Text()('<samp>'), Text({code: ['HtmlSamp']})('HtmlSamp'), Text({link: [{text:'Link', target:'_blank', url:'https://www.w3schools.com/tags/tag_samp.asp'}]})('Link'), readText('yes')(),
                Text()('<script>'), Text({code: ['HtmlScript']})('HtmlScript'), Text({link: [{text:'Link', target:'_blank', url:'https://www.w3schools.com/tags/tag_script.asp'}]})('Link'), readText('yes')(),
                Text()('<section>'), Text({code: ['HtmlSection']})('HtmlSection'), Text({link: [{text:'Link', target:'_blank', url:'https://www.w3schools.com/tags/tag_section.asp'}]})('Link'), readText('yes')(),
                Text()('<select>'), Text({code: ['HtmlSelect']})('HtmlSelect'), Text({link: [{text:'Link', target:'_blank', url:'https://www.w3schools.com/tags/tag_select.asp'}]})('Link'), readText('yes')(),
                Text()('<small>'), Text({code: ['HtmlSmall']})('HtmlSmall'), Text({link: [{text:'Link', target:'_blank', url:'https://www.w3schools.com/tags/tag_small.asp'}]})('Link'), readText('yes')(),
                Text()('<source>'), Text({code: ['HtmlSource']})('HtmlSource'), Text({link: [{text:'Link', target:'_blank', url:'https://www.w3schools.com/tags/tag_source.asp'}]})('Link'), readText('no')(),
                Text()('<span>'), Text({code: ['HtmlSpan']})('HtmlSpan'), Text({link: [{text:'Link', target:'_blank', url:'https://www.w3schools.com/tags/tag_span.asp'}]})('Link'), readText('yes')(),
                Text()('<strong>'), Text({code: ['HtmlStrong']})('HtmlStrong'), Text({link: [{text:'Link', target:'_blank', url:'https://www.w3schools.com/tags/tag_strong.asp'}]})('Link'), readText('yes')(),
                Text()('<style>'), Text({code: ['HtmlStyle']})('HtmlStyle'), Text({link: [{text:'Link', target:'_blank', url:'https://www.w3schools.com/tags/tag_style.asp'}]})('Link'), readText('yes')(),
                Text()('<sub>'), Text({code: ['HtmlSub']})('HtmlSub'), Text({link: [{text:'Link', target:'_blank', url:'https://www.w3schools.com/tags/tag_sub.asp'}]})('Link'), readText('yes')(),
                Text()('<summary>'), Text({code: ['HtmlSummary']})('HtmlSummary'), Text({link: [{text:'Link', target:'_blank', url:'https://www.w3schools.com/tags/tag_summary.asp'}]})('Link'), readText('yes')(),
                Text()('<sup>'), Text({code: ['HtmlSup']})('HtmlSup'), Text({link: [{text:'Link', target:'_blank', url:'https://www.w3schools.com/tags/tag_sup.asp'}]})('Link'), readText('yes')(),
                Text()('<svg>'), Text({code: ['HtmlSvg']})('HtmlSvg'), Text({link: [{text:'Link', target:'_blank', url:'https://www.w3schools.com/tags/tag_svg.asp'}]})('Link'), readText('yes')(),
                Text()('<table>'), Text({code: ['HtmlTable']})('HtmlTable'), Text({link: [{text:'Link', target:'_blank', url:'https://www.w3schools.com/tags/tag_table.asp'}]})('Link'), readText('yes')(),
                Text()('<tbody>'), Text({code: ['HtmlTbody']})('HtmlTbody'), Text({link: [{text:'Link', target:'_blank', url:'https://www.w3schools.com/tags/tag_tbody.asp'}]})('Link'), readText('yes')(),
                Text()('<td>'), Text({code: ['HtmlTd']})('HtmlTd'), Text({link: [{text:'Link', target:'_blank', url:'https://www.w3schools.com/tags/tag_td.asp'}]})('Link'), readText('yes')(),
                Text()('<template>'), Text({code: ['HtmlTemplate']})('HtmlTemplate'), Text({link: [{text:'Link', target:'_blank', url:'https://www.w3schools.com/tags/tag_template.asp'}]})('Link'), readText('yes')(),
                Text()('<textarea>'), Text({code: ['HtmlTextarea']})('HtmlTextarea'), Text({link: [{text:'Link', target:'_blank', url:'https://www.w3schools.com/tags/tag_textarea.asp'}]})('Link'), readText('yes')(),
                Text()('<tfoot>'), Text({code: ['HtmlTfoot']})('HtmlTfoot'), Text({link: [{text:'Link', target:'_blank', url:'https://www.w3schools.com/tags/tag_tfoot.asp'}]})('Link'), readText('yes')(),
                Text()('<th>'), Text({code: ['HtmlTh']})('HtmlTh'), Text({link: [{text:'Link', target:'_blank', url:'https://www.w3schools.com/tags/tag_th.asp'}]})('Link'), readText('yes')(),
                Text()('<thead>'), Text({code: ['HtmlThead']})('HtmlThead'), Text({link: [{text:'Link', target:'_blank', url:'https://www.w3schools.com/tags/tag_thead.asp'}]})('Link'), readText('yes')(),
                Text()('<time>'), Text({code: ['HtmlTime']})('HtmlTime'), Text({link: [{text:'Link', target:'_blank', url:'https://www.w3schools.com/tags/tag_time.asp'}]})('Link'), readText('yes')(),
                Text()('<title>'), Text({code: ['HtmlTitle']})('HtmlTitle'), Text({link: [{text:'Link', target:'_blank', url:'https://www.w3schools.com/tags/tag_title.asp'}]})('Link'), readText('yes')(),
                Text()('<tr>'), Text({code: ['HtmlTr']})('HtmlTr'), Text({link: [{text:'Link', target:'_blank', url:'https://www.w3schools.com/tags/tag_tr.asp'}]})('Link'), readText('yes')(),
                Text()('<track>'), Text({code: ['HtmlTrack']})('HtmlTrack'), Text({link: [{text:'Link', target:'_blank', url:'https://www.w3schools.com/tags/tag_track.asp'}]})('Link'), readText('no')(),
                Text()('<u>'), Text({code: ['HtmlU']})('HtmlU'), Text({link: [{text:'Link', target:'_blank', url:'https://www.w3schools.com/tags/tag_u.asp'}]})('Link'), readText('yes')(),
                Text()('<ul>'), Text({code: ['HtmlUl']})('HtmlUl'), Text({link: [{text:'Link', target:'_blank', url:'https://www.w3schools.com/tags/tag_ul.asp'}]})('Link'), readText('yes')(),
                Text()('<var>'), Text({code: ['HtmlVar']})('HtmlVar'), Text({link: [{text:'Link', target:'_blank', url:'https://www.w3schools.com/tags/tag_var.asp'}]})('Link'), readText('yes')(),
                Text()('<video>'), Text({code: ['HtmlVideo']})('HtmlVideo'), Text({link: [{text:'Link', target:'_blank', url:'https://www.w3schools.com/tags/tag_video.asp'}]})('Link'), readText('yes')(),
                Text()('<wbr>'), Text({code: ['HtmlWbr']})('HtmlWbr'), Text({link: [{text:'Link', target:'_blank', url:'https://www.w3schools.com/tags/tag_wbr.asp'}]})('Link'), readText('no')(),
            ]),
            readText('nativeComponents')({}),
            readText('nativeComponentsP1')({}),
            Table({columns: 4, style: {maxHeight: 500}})([
                readText('tag')(), readText('function')(), readText('description')(), readText('hasChildren')(),
                Text()('ActivityIndicator'), Text({code:['RNActivityIndicator']})('RNActivityIndicator'), Text({link:[{text:'Link', target:'_blank', url:'https://reactnative.dev/docs/activityindicator'}]})('Link'), readText('no')(),
                Text()('Button'), Text({code:['RNButton']})('RNButton'), Text({link:[{text:'Link', target:'_blank', url:'https://reactnative.dev/docs/button'}]})('Link'), readText('no')(),
                Text()('FlatList'), Text({code:['RNFlatList']})('RNFlatList'), Text({link:[{text:'Link', target:'_blank', url:'https://reactnative.dev/docs/flatlist'}]})('Link'), readText('no')(),
                Text()('Image'), Text({code:['RNImage']})('RNImage'), Text({link:[{text:'Link', target:'_blank', url:'https://reactnative.dev/docs/image'}]})('Link'), readText('no')(),
                Text()('ImageBackground'), Text({code:['RNImageBackground']})('RNImageBackground'), Text({link:[{text:'Link', target:'_blank', url:'https://reactnative.dev/docs/imagebackground'}]})('Link'), readText('yes')(),
                Text()('KeyboardAvoidingView'), Text({code:['RNKeyboardAvoidingView']})('RNKeyboardAvoidingView'), Text({link:[{text:'Link', target:'_blank', url:'https://reactnative.dev/docs/keyboardavoidingview'}]})('Link'), readText('yes')(),
                Text()('Modal'), Text({code:['RNModal']})('RNModal'), Text({link:[{text:'Link', target:'_blank', url:'https://reactnative.dev/docs/modal'}]})('Link'), readText('yes')(),
                Text()('Pressable'), Text({code:['RNPressable']})('RNPressable'), Text({link:[{text:'Link', target:'_blank', url:'https://reactnative.dev/docs/pressable'}]})('Link'), readText('yes')(),
                Text()('RefreshControl'), Text({code:['RNRefreshControl']})('RNRefreshControl'), Text({link:[{text:'Link', target:'_blank', url:'https://reactnative.dev/docs/refreshcontrol'}]})('Link'), readText('no')(),
                Text()('ScrollView'), Text({code:['RNScrollView']})('RNScrollView'), Text({link:[{text:'Link', target:'_blank', url:'https://reactnative.dev/docs/scrollview'}]})('Link'), readText('yes')(),
                Text()('SectionList'), Text({code:['RNSectionList']})('RNSectionList'), Text({link:[{text:'Link', target:'_blank', url:'https://reactnative.dev/docs/sectionlist'}]})('Link'), readText('no')(),
                Text()('StatusBar'), Text({code:['RNStatusBar']})('RNStatusBar'), Text({link:[{text:'Link', target:'_blank', url:'https://reactnative.dev/docs/statusbar'}]})('Link'), readText('no')(),
                Text()('Switch'), Text({code:['RNSwitch']})('RNSwitch'), Text({link:[{text:'Link', target:'_blank', url:'https://reactnative.dev/docs/switch'}]})('Link'), readText('no')(),
                Text()('Text'), Text({code:['RNText']})('RNText'), Text({link:[{text:'Link', target:'_blank', url:'https://reactnative.dev/docs/text'}]})('Link'), readText('yes')(),
                Text()('TextInput'), Text({code:['RNTextInput']})('RNTextInput'), Text({link:[{text:'Link', target:'_blank', url:'https://reactnative.dev/docs/textinput'}]})('Link'), readText('no')(),
                Text()('TouchableHighlight'), Text({code:['RNTouchableHighlight']})('RNTouchableHighlight'), Text({link:[{text:'Link', target:'_blank', url:'https://reactnative.dev/docs/touchablehighlight'}]})('Link'), readText('yes')(),
                Text()('TouchableOpacity'), Text({code:['RNTouchableOpacity']})('RNTouchableOpacity'), Text({link:[{text:'Link', target:'_blank', url:'https://reactnative.dev/docs/touchableopacity'}]})('Link'), readText('yes')(),
                Text()('TouchableWithoutFeedback'), Text({code:['RNTouchableWithoutFeedback']})('RNTouchableWithoutFeedback'), Text({link:[{text:'Link', target:'_blank', url:'https://reactnative.dev/docs/touchablewithoutfeedback'}]})('Link'), readText('yes')(),
                Text()('View'), Text({code:['RNView']})('RNView'), Text({link:[{text:'Link', target:'_blank', url:'https://reactnative.dev/docs/view'}]})('Link'), readText('yes')(),
                Text()('VirtualizedList'), Text({code:['RNVirtualizedList']})('RNVirtualizedList'), Text({link:[{text:'Link', target:'_blank', url:'https://reactnative.dev/docs/virtualizedlist'}]})('Link'), readText('no')(),
                Text()('DrawerLayoutAndroid'), Text({code:['RNDrawerLayoutAndroid']})('RNDrawerLayoutAndroid'), Text({link:[{text:'Link', target:'_blank', url:'https://reactnative.dev/docs/drawerlayoutandroid'}]})('Link'), readText('yes')(),
                Text()('TouchableNativeFeedback'), Text({code:['RNTouchableNativeFeedback']})('RNTouchableNativeFeedback'), Text({link:[{text:'Link', target:'_blank', url:'https://reactnative.dev/docs/touchablenativefeedback'}]})('Link'), readText('yes')(),
                Text()('InputAccessoryView'), Text({code:['RNInputAccessoryView']})('RNInputAccessoryView'), Text({link:[{text:'Link', target:'_blank', url:'https://reactnative.dev/docs/inputaccessoryview'}]})('Link'), readText('yes')(),
                Text()('SafeAreaView'), Text({code:['RNSafeAreaView']})('RNSafeAreaView'), Text({link:[{text:'Link', target:'_blank', url:'https://reactnative.dev/docs/safeareaview'}]})('Link'), readText('yes')(),
                // Text()('AnimatedView'), Text({code:['RNAnimatedView']})('RNAnimatedView'), Text({link:[{text:'Link', target:'_blank', url:'https://reactnative.dev/docs/animatedview'}]})('Link'), readText('yes')(),
            ]),
            readText('example')({}),
            CodeDisplay({
                template: 'oneJS', type: 'both', flavor: readFlavor('shadow'), size: {width: 500, height: 'auto'},
                code: `import {app, read, update} from '@onejs-dev/core';
import {HtmlDiv, HtmlH1, HtmlInput} from '@onejs-dev/components';

const state = {name: 'World'}

const App = () => HtmlDiv()([
    HtmlH1()(\`Hello \${read('name')}!\`),
    HtmlInput({value: read('name'), onChange: update('name'), placeholder: 'Name'})
]);

app({component: App, state: state});`}),
            Input({
                type: 'button', url: '/docs', title: 'Back to Docs',
                flavor: readFlavor('light', 'primaryBackground')
            })
        ]),
        View({            
            animation: {visible: ['fade-in', 'fade-out']}, visible: read('doc') === 'View',
            content: {h: 'stretch', v: 'top', direction: 'column', gap: 20},
            self: {align: 'center'}, style: readStyle('pageContent')
        })([ 
            Text({flavor: readFlavor('section'), style: readStyle('unitTitle')})('View'),
            readText('ViewH1P1')({}),
            readText('input')({}),
            Table({columns: 5, template: '2fr 1fr 1fr 4fr 1fr'})([
                readText('name')(), readText('type')(), readText('mandatory')(), readText('description')(), readText('defaultValue')(),
                Text({code: ['type']})('type'), Text({code: ['String']})('String'), readText('no')(), readText('ViewType')(), Text({code: ['div']})('div'),
                Text({code: ['visible']})('visible'), Text({code: ['Boolean']})('Boolean'), readText('no')(), readText('ViewVisible')(), Text({code: ['true']})('true'),
                Text({code: ['onVisibleChange']})('onVisibleChange'), Text({code: ['Function']})('Function'), readText('no')(), readText('ViewOnVisibleChange')(), Text()(''),
                Text({code: ['content']})('content'), Text({code: ['Object']})('Object'), readText('no')(), readText('ViewContent')(), Text()(''),
                Text({code: ['self']})('self'), Text({code: ['Object']})('Object'), readText('no')(), readText('ViewSelf')(), Text()(''),
                Text({code: ['animation']})('animation'), Text({code: ['Object']})('Object'), readText('no')(), readText('ViewAnimation')(), Text()(''),
                Text({code: ['url']})('url'), Text({code: ['String']})('String'), readText('no')(), readText('Url')(), Text()(''),
                Text({code: ['flavor']})('flavor'), Text({code: ['Object']})('Object'), readText('no')(), View({content: {direction: 'column'}})([readText('Flavor1')(), readText('ViewFlavor')(), readText('Flavor2')()]), Text()(''),
                Text({code: ['structure']})('structure'), Text({code: ['Component']})('Component'), readText('no')(), readText('Structure')(), Text()(''),
            ]),
            readText('output')({}),
            Table({columns: 2, template: '2fr 7fr'})([
                Text()('Type'), Text()('Description'),
                Text({code: ['Component']})('Component'), readText('ViewOutput')()
            ]),
            readText('example')({}),
            CodeDisplay({
                template: 'oneJS', type: 'both', flavor: readFlavor('shadow'), size: {width: 500},
                code: `import {app, read, update, readFlavor, readStyle} from '@onejs-dev/core';
import {View, Input, Text} from '@onejs-dev/components';

const state = {
    visible: true,
    onVisibleChange: () => {console.log("'visible' property changed!")},
    contentDirection: 'row',
    selfExpand: 0,
    animationVisible: 'fade-in',
    url: '/test',
    flavorId: 'primaryBackground'
}

const contentDirectionOptions = ['row', 'column'];
const selfExpandOptions = [0, 1];
const animationVisibleOptions = ['fade-in', 'expand'];
const flavorOptions = ['primaryBackground', 'rejectBackground'];

const style = {
    text: {width: 200},
    view: {width: 60, height: 60}
};

const App = () => {
    return  View({content: {direction: 'row', h: 'center', v: 'center', wrap: true, gap: 10}, self: {expand: 1}})([
        View()([Text({flavor: readFlavor('bold'), style: readStyle('text')})('Visible:'),
            Input({type: 'switch', value: read('visible'), onChange: update('visible')})]),
        View()([Text({flavor: readFlavor('bold'), style: readStyle('text')})('Content direction:'),
            Input({type: 'list', options: contentDirectionOptions, value: read('contentDirection'), onChange: update('contentDirection')})]),
        View()([Text({flavor: readFlavor('bold'), style: readStyle('text')})('Self expand:'),
            Input({type: 'range', value: read('selfExpand'), onChange: update('selfExpand'), min: 0, max: 4})]),
        View()([Text({flavor: readFlavor('bold'), style: readStyle('text')})('Visible animation:'),
            Input({type: 'list', options: animationVisibleOptions, value: read('animationVisible'), onChange: update('animationVisible')})]),
        View()([Text({flavor: readFlavor('bold'), style: readStyle('text')})('Flavor:'),
            Input({type: 'list', options: flavorOptions, value: read('flavorId'), onChange: update('flavorId')})]),
        View({flavor: readFlavor('lightBackground'), self: {expand: 1}, content: {h: 'space', v: 'top', gap: 20, direction: read('contentDirection')}})([
            View({self: {expand: 1}, flavor: readFlavor('outline'), style: readStyle('view')})(),
            View({visible: read('visible'), flavor: readFlavor(read('flavorId')), self: {expand: read('selfExpand')}, animation: {visible: [read('animationVisible'), 'fade-out']}, style: readStyle('view')})(Text()()),
            View({self: {expand: 1}, flavor: readFlavor('outline'), style: readStyle('view')})(),
            View({self: {expand: 1}, flavor: readFlavor('outline'), style: readStyle('view')})(),
        ])
    ]);
};

app({component: App, state: state, style: style, theme: {preset: 'oneJS'}});`}),
            Input({
                type: 'button', url: '/docs', title: 'Back to Docs',
                flavor: readFlavor('light', 'primaryBackground')
            })
        ]), 
        View({            
            animation: {visible: ['fade-in', 'fade-out']}, visible: read('doc') === 'Input',
            content: {h: 'stretch', v: 'top', direction: 'column', gap: 20},
            self: {align: 'center'}, style: readStyle('pageContent')
        })([ 
            Text({flavor: readFlavor('section'), style: readStyle('unitTitle')})('Input'),
            readText('InputH1P1')({}),
            readText('input')({}),
            Table({columns: 5, template: '2fr 1fr 1fr 4fr 1fr'})([
                readText('name')(), readText('type')(), readText('mandatory')(), readText('description')(), readText('defaultValue')(),
                Text({code: ['type']})('type'), Text({code: ['String']})('String'), readText('yes')(), View({content: {direction: 'column'}})([readText('InputType')(), readText('InputTypeList')()]), Text({code: ['text']})('text'),
                Text({code: ['options']})('options'), Text({code: ['Array(String)', 'Array(Object)']})('Array(String) or Array(Object)'), readText('no')(),View({content: {direction: 'column'}})([readText('InputOptions')(), 
                    CodeDisplay({
                        type: 'viewer', flavor: readFlavor('shadow'),
                        code: `/* Array(String) */
stringOptions = ['Option A', 'Option B'];

/* Array(Object) */
objectOptions = [
    {value: 'A', label: 'Option A'},
    {value: 'B', label: 'Option B'}
];`
                    }),]), Text()(''),
                Text({code: ['title']})('title'), Text({code: ['String']})('String'), readText('no')(), readText('InputTitle')(), Text()(''),
                Text({code: ['titleStyle']})('titleStyle'), Text({code: ['Object']})('Object'), readText('no')(), readText('InputTitleStyle')(), Text()(''),
                Text({code: ['icon']})('icon'), Text({code: ['Object']})('Object'), readText('no')(), readText('InputIcon')(), Text()(''),
                Text({code: ['iconStyle']})('iconStyle'), Text({code: ['Object']})('Object'), readText('no')(), readText('InputIconStyle')(), Text()(''),
                Text({code: ['flavor']})('flavor'), Text({code: ['Object']})('Object'), readText('yes')(), View({content: {direction: 'column'}})([readText('Flavor1')(), readText('InputFlavor')(), readText('Flavor2')()]), Text({code: ['default']})('default flavor'),
            ]),
            readText('output')({}),
            Table({columns: 2, template: '2fr 7fr'})([
                Text()('Type'), Text()('Description'),
                Text({code: ['Component']})('Component'), readText('InputOutput')()
            ]),
            readText('example')({}),
            CodeDisplay({
                template: 'oneJS', type: 'both', flavor: readFlavor('shadow'), size: {width: 500, height: 'auto'},
                code: `import {app, read, update, readFlavor} from '@onejs-dev/core';
import {View, Input, Text} from '@onejs-dev/components';

const state = {
    inputValue: '',
    inputType: 'text'
};
const inputTypes = ['text', 'textarea', 'button', 'list', 'range', 'checkbox', 'switch'];

const App = () => View({content: {direction: 'column', h: 'center', v: 'center', gap: 10}, self: {expand: 1}})([
    Text({flavor: readFlavor('header')})('Choose Input'),
    Input({type: 'list', options: inputTypes, value: read('inputType'), onChange: update('inputType')}),
    Input({
        type: read('inputType'), value: read('inputValue'), onChange: update('inputValue'), 
        title: 'Input Title', placeholder: 'Input Placeholder', options: ['A', 'B', 'C']
    }),
    Text()('Input Value: ' + read('inputValue'))
]);

app({component: App, state: state, theme: {preset: 'oneJS'}});`}),
            Input({
                type: 'button', url: '/docs', title: 'Back to Docs',
                flavor: readFlavor('light', 'primaryBackground')
            })
        ]), 
        View({            
            animation: {visible: ['fade-in', 'fade-out']}, visible: read('doc') === 'Text',
            content: {h: 'stretch', v: 'top', direction: 'column', gap: 20},
            self: {align: 'center'}, style: readStyle('pageContent')
        })([ 
            Text({flavor: readFlavor('section'), style: readStyle('unitTitle')})('Text'),
            readText('TextH1P1')({}),
            readText('input')({}),
            Table({columns: 5, template: '2fr 1fr 1fr 4fr 1fr'})([
                readText('name')(), readText('type')(), readText('mandatory')(), readText('description')(), readText('defaultValue')(),
                Text({code: ['type']})('type'), Text({code: ['String']})('String'), readText('no')(), View({content: {direction: 'column'}})([readText('TextType')(), readText('TextTypeList')()]), Text()(''),
                Text({code: ['link']})('link'), Text({code: ['String', 'Array(Object)']})('String or Array(Object)'), readText('no')(),View({content: {direction: 'column'}})([readText('TextLink')(), readText('TextLinkList')()]), Text()(''),
                Text({code: ['emphasis']})('emphasis'), Text({code: ['String']})('String'), readText('no')(), readText('TextEmphasis')(), Text()(''),
                Text({code: ['code']})('code'), Text({code: ['Array(String)']})('Array(String)'), readText('no')(), readText('TextCode')(), Text()(''),
                Text({code: ['list']})('list'), Text({code: ['String']})('String'), readText('no')(), readText('TextList')(), Text()(''),
                Text({code: ['flavor']})('flavor'), Text({code: ['Object']})('Object'), readText('yes')(), View({content: {direction: 'column'}})([readText('Flavor1')(), readText('TextFlavor')(), readText('Flavor2')()]), Text({code: ['default']})('default flavor'),
                Text({code: ['structure']})('structure'), Text({code: ['String']})('String'), readText('no')(), readText('TextStructure')(), Text()(''),
            ]),
            readText('output')({}),
            Table({columns: 2, template: '2fr 7fr'})([
                Text()('Type'), Text()('Description'),
                Text({code: ['Component']})('Component'), readText('TextOutput')()
            ]),
            readText('example')({}),
            CodeDisplay({
                template: 'oneJS', type: 'both', flavor: readFlavor('shadow'), size: {width: 500, height: 500},
                code: `import {app, read, update, readFlavor} from '@onejs-dev/core';
import {View, Input, Text} from '@onejs-dev/components';

const state = {
    textType: 'title',
    inputType: 'text'
};
const textTypes = ['title', 'header', 'section', 'subsection'];

const App = () => View({content: {direction: 'column', h: 'center', v: 'center', gap: 10}, self: {expand: 1}})([
    Text({flavor: readFlavor(read('textType'))})('Title Text'),
    Input({type: 'list', options: textTypes, value: read('textType'), onChange: update('textType')}),
    Text({
        emphasis: [{text: 'emphasis', style: {fontWeight: 'bold'}}],
        link: [{text: 'hyperlinks', url: '', target: '_blank'}],
        code: ['code']
    })(\`Sample text to display emphasis, hyperlinks, code and lists:\`),
    Text({list: 'bullets'})(\`Item A
                             Item B
                             Item C\`)
]);

app({component: App, state: state, theme: {preset: 'oneJS'}});`}),
            Input({
                type: 'button', url: '/docs', title: 'Back to Docs',
                flavor: readFlavor('light', 'primaryBackground')
            })
        ]), 
        View({            
            animation: {visible: ['fade-in', 'fade-out']}, visible: read('doc') === 'Icon',
            content: {h: 'stretch', v: 'top', direction: 'column', gap: 20},
            self: {align: 'center'}, style: readStyle('pageContent')
        })([ 
            Text({flavor: readFlavor('section'), style: readStyle('unitTitle')})('Icon'),
            readText('IconH1P1')({}),
            readText('IconH1P2')({}),
            CodeDisplay({
                type: 'viewer', flavor: readFlavor('shadow'), 
                code: `export myIconFont = {icon1: '<svg>...</svg>', icon2: '<svg>...</svg>'};`}),
            readText('input')({}),
//             CodeDisplay({
//                 type: 'viewer', flavor: readFlavor('shadow'),
//                 code: `/* Icon Definition */
// export const Icon = Component('Icon', false, ({icon, raised, size = 32,
//     flavor = readFlavor('default'), ...attributes} = {}) => {...});`}),
            Table({columns: 5, template: '2fr 1fr 1fr 4fr 1fr'})([
                readText('name')(), readText('type')(), readText('mandatory')(), readText('description')(), readText('defaultValue')(),
                Text({code: ['icon']})('icon'), Text({code: ['Object']})('Object'), readText('yes')(), readText('IconIcon')(), Text()(''),
                Text({code: ['raised']})('raised'), Text({code: ['Boolean']})('Boolean'), readText('no')(),readText('IconRaised')(), Text({code: ['false']})('false'),
                Text({code: ['size']})('size'), Text({code: ['Number', 'Object']})('Number or Object'), readText('no')(), View({content: {direction: 'column'}})([readText('IconSize')(), readText('IconSizeList')()]), Text({code: ['32']})('32'),
                Text({code: ['flavor']})('flavor'), Text({code: ['Object']})('Object'), readText('yes')(), View({content: {direction: 'column'}})([readText('Flavor1')(), readText('IconFlavor')(), readText('Flavor2')()]), Text({code: ['default']})('default flavor'),
            ]),
            readText('output')({}),
            Table({columns: 2, template: '2fr 7fr'})([
                Text()('Type'), Text()('Description'),
                Text({code: ['Component']})('Component'), readText('IconOutput')()
            ]),
            readText('example')({}),
            CodeDisplay({
                template: 'oneJS', type: 'both', flavor: readFlavor('shadow'), size: {width: 500, height: 500}, dependencies: {'@onejs-dev/icons': '1.0.1'},
                code: `import {app, read, update, readFlavor} from '@onejs-dev/core';
import {View, Input, Text, Icon} from '@onejs-dev/components';
import {icons} from '@onejs-dev/icons';

const state = {
    raised: false,
    size: 64
};
const textTypes = ['title', 'header', 'section', 'subsection'];

const App = () => View({content: {direction: 'column', h: 'center', v: 'center', gap: 10}, self: {expand: 1}})([
    Text({flavor: readFlavor('header')})('Icons'),
    Input({type: 'switch', value: read('raised'), onChange: update('raised')}),
    Input({type: 'range', value: read('size'), onChange: update('size'), min: 32, max: 64}),
    Icon({icon: icons['water'], raised: read('raised'), flavor: readFlavor('primaryGradient', 'shadow'), size: read('size')}),
    Icon({icon: icons['flame'], raised: read('raised'), flavor: readFlavor('rejectGradient', 'shadow'), size: read('size')}),
    Icon({icon: icons['leaf'], raised: read('raised'), flavor: readFlavor('acceptGradient', 'shadow'), size: read('size')})
]);

app({component: App, state: state, theme: {preset: 'oneJS'}});`}),
            Input({
                type: 'button', url: '/docs', title: 'Back to Docs',
                flavor: readFlavor('light', 'primaryBackground')
            })
        ]), 
        View({            
            animation: {visible: ['fade-in', 'fade-out']}, visible: read('doc') === 'read',
            content: {h: 'stretch', v: 'top', direction: 'column', gap: 20},
            self: {align: 'center'}, style: readStyle('pageContent')
        })([ 
            Text({flavor: readFlavor('section'), style: readStyle('unitTitle')})('read'),
            readText('readH1P1')({}),
            readText('input')({}),
            Table({columns: 5, template: '2fr 1fr 1fr 4fr 1fr'})([
                readText('name')(), readText('type')(), readText('mandatory')(), readText('description')(), readText('defaultValue')(),
                Text({code: ['stateId']})('stateId'), Text({code: ['String']})('String'), readText('yes')(), readText('readStateId')(), Text()(''),
                Text({code: ['documentId']})('documentId'), Text({code: ['String']})('String'), readText('no')(),readText('readDocumentId')(), Text()(''),
            ]),
            readText('output')({}),
            Table({columns: 2, template: '2fr 7fr'})([
                Text()('Type'), Text()('Description'),
                Text()('Any'), readText('readOutput')()
            ]),
            readText('example')({}),
            CodeDisplay({
                template: 'oneJS', type: 'both', flavor: readFlavor('shadow'), size: {width: 500, height: 'auto'},
                code: `import {app, read} from '@onejs-dev/core';
import {View, Text} from '@onejs-dev/components';

const state = {
    framework: 'oneJS',
    list: [{id: 'js', name: 'JavaScript'}, {id: 'css', name: 'CSS'}, {id: 'html', name: 'HTML'}]
};

const App = () => View({content: {direction: 'column', h: 'center', v: 'center', gap: 10}, self: {expand: 1}})([
    Text()('My favorite framework: ' + read('framework')),
    Text()('My favorite language: ' + read('list', 'js').name),
]);

app({component: App, state: state, theme: {preset: 'oneJS'}});`}),
            Input({
                type: 'button', url: '/docs', title: 'Back to Docs',
                flavor: readFlavor('light', 'primaryBackground')
            })
        ]),
        View({            
            animation: {visible: ['fade-in', 'fade-out']}, visible: read('doc') === 'update',
            content: {h: 'stretch', v: 'top', direction: 'column', gap: 20},
            self: {align: 'center'}, style: readStyle('pageContent')
        })([ 
            Text({flavor: readFlavor('section'), style: readStyle('unitTitle')})('update'),
            readText('updateH1P1')({}),
            readText('input')({}),
            Table({columns: 5, template: '2fr 1fr 1fr 4fr 1fr'})([
                readText('name')(), readText('type')(), readText('mandatory')(), readText('description')(), readText('defaultValue')(),
                Text({code: ['stateId']})('stateId'), Text({code: ['String']})('String'), readText('yes')(), readText('updateStateId')(), Text()(''),
                Text({code: ['documentId']})('documentId'), Text({code: ['String']})('String'), readText('no')(),readText('updateDocumentId')(), Text()(''),
                Text({code: ['event']})('event'), Text({code: ['Object']})('Object or Any'), readText('yes')(), View({content: {direction: 'column'}})([readText('updateEvent')(), readText('updateEventList')()]), Text()(''),
            ]),
            readText('output')({}),
            readText('none')(),
            readText('example')({}),
            CodeDisplay({
                template: 'oneJS', type: 'both', flavor: readFlavor('shadow'), size: {width: 500, height: 'auto'},
                code: `import {app, read, update} from '@onejs-dev/core';
import {View, Text, Input} from '@onejs-dev/components';

const state = {result: 5};

const App = () => View({content: {direction: 'column', h: 'center', v: 'center', gap: 10}, self: {expand: 1}})([
    Text()('Result: ' + read('result')),
    Input({type: 'number', value: read('result'), onChange: update('result')}),
    Input({type: 'button', title: 'Add one', onClick: () => {
        const result = Number(read('result')) + 1;
        update('result')(result);
    }})
]);

app({component: App, state: state, theme: {preset: 'oneJS'}});`}),
            Input({
                type: 'button', url: '/docs', title: 'Back to Docs',
                flavor: readFlavor('light', 'primaryBackground')
            })
        ]),
        View({            
            animation: {visible: ['fade-in', 'fade-out']}, visible: read('doc') === 'add',
            content: {h: 'stretch', v: 'top', direction: 'column', gap: 20},
            self: {align: 'center'}, style: readStyle('pageContent')
        })([ 
            Text({flavor: readFlavor('section'), style: readStyle('unitTitle')})('add'),
            readText('addH1P1')({}),
            readText('input')({}),
            Table({columns: 5, template: '2fr 1fr 1fr 4fr 1fr'})([
                readText('name')(), readText('type')(), readText('mandatory')(), readText('description')(), readText('defaultValue')(),
                Text({code: ['stateId']})('stateId'), Text({code: ['String']})('String'), readText('yes')(), readText('addStateId')(), Text()(''),
                Text({code: ['event']})('event'), Text({code: ['Object']})('Object or Any'), readText('yes')(), View({content: {direction: 'column'}})([readText('addEvent')(), readText('addEventList')()]), Text()(''),
            ]),
            readText('output')({}),
            readText('none')(),
            readText('example')({}),
            CodeDisplay({
                template: 'oneJS', type: 'both', flavor: readFlavor('shadow'), size: {width: 500, height: 'auto'},
                code: `import {app, read, update, add} from '@onejs-dev/core';
import {View, Input, Text} from '@onejs-dev/components';

const state = {note: '', notes: []};

const App = () => View({content: {direction: 'column', h: 'center', v: 'center', gap: 10}, self: {expand: 1}})([
    View()([
        Input({type: 'text', value: read('note'), onChange: update('note')}),
        Input({type: 'button', title: 'Save', onClick: () => add('notes')(read('note'))})
    ]),
    read('notes').map(note => Text({list: 'bullets'})(note))
]);

app({component: App, state: state, theme: {preset: 'oneJS'}});`}),
            Input({
                type: 'button', url: '/docs', title: 'Back to Docs',
                flavor: readFlavor('light', 'primaryBackground')
            })
        ]),
        View({            
            animation: {visible: ['fade-in', 'fade-out']}, visible: read('doc') === 'remove',
            content: {h: 'stretch', v: 'top', direction: 'column', gap: 20},
            self: {align: 'center'}, style: readStyle('pageContent')
        })([ 
            Text({flavor: readFlavor('section'), style: readStyle('unitTitle')})('remove'),
            readText('removeH1P1')({}),
            readText('input')({}),
            Table({columns: 5, template: '2fr 1fr 1fr 4fr 1fr'})([
                readText('name')(), readText('type')(), readText('mandatory')(), readText('description')(), readText('defaultValue')(),
                Text({code: ['stateId']})('stateId'), Text({code: ['String']})('String'), readText('yes')(), readText('removeStateId')(), Text()(''),
                Text({code: ['documentId']})('documentId'), Text({code: ['String']})('String'), readText('no')(),readText('removeDocumentId')(), Text()(''),
            ]),
            readText('output')({}),
            readText('none')(),
            readText('example')({}),
            CodeDisplay({
                template: 'oneJS', type: 'both', flavor: readFlavor('shadow'), size: {width: 500, height: 500},
                code: `import {app, read, update, remove, readFlavor} from '@onejs-dev/core';
import {View, Text, Input} from '@onejs-dev/components';

const state = {
    id: '', 
    languages: [
        {id: 'zh', name: 'Chinese'},
        {id: 'en', name: 'English'}, 
        {id: 'es', name: 'Spanish'},
        {id: 'hi', name: 'Hindi'}
    ]
};

const App = () => View({content: {direction: 'column', h: 'center', v: 'center', gap: 10}, self: {expand: 1}})([
    Text({flavor: readFlavor('section')})('Languages in my CV: '),
    View()([
        Input({type: 'text', value: read('id'), onChange: update('id'), placeholder: 'Language id'}),
        Input({type: 'button', title: 'Remove', flavor: readFlavor('reject'), onClick: () => {      
            remove('languages', read('id'));
        }})
    ]),
    read('languages').map(language => Text()(language.name))
]);

app({component: App, state: state, theme: {preset: 'oneJS'}});`}),
            Input({
                type: 'button', url: '/docs', title: 'Back to Docs',
                flavor: readFlavor('light', 'primaryBackground')
            })
        ]),
        View({            
            animation: {visible: ['fade-in', 'fade-out']}, visible: read('doc') === 'BaseComponent',
            content: {h: 'stretch', v: 'top', direction: 'column', gap: 20},
            self: {align: 'center'}, style: readStyle('pageContent')
        })([ 
            Text({flavor: readFlavor('section'), style: readStyle('unitTitle')})('BaseComponent'),
            readText('BaseComponentH1P1')({}),
            readText('input')({}),
            Table({columns: 5, template: '2fr 1fr 1fr 4fr 1fr'})([
                readText('name')(), readText('type')(), readText('mandatory')(), readText('description')(), readText('defaultValue')(),
                Text({code: ['name']})('name'), Text({code: ['String']})('String'), readText('yes')(), readText('BaseComponentName')(), Text()(''),
                Text({code: ['hasChildren']})('hasChildren'), Text({code: ['Boolean']})('Boolean'), readText('yes')(),readText('BaseComponentHasChildren')(), Text()(''),
                Text({code: ['ComponentFunctionOrTag']})('ComponentFunctionOrTag'), Text({code: ['String', 'Component']})('String or Component'), readText('yes')(),View({content: {direction: 'column'}})([readText('BaseComponentComponentFunctionOrTag')(), readText('BaseComponentComponentFunctionOrTagList')()]), Text()(''),
            ]),
            readText('output')({}),
            Table({columns: 2, template: '2fr 7fr'})([
                Text()('Type'), Text()('Description'),
                Text({code: ['Component']})('Component'), readText('BaseComponentOutput')()
            ]),
            readText('example')({}),
            CodeDisplay({
                template: 'oneJS', type: 'both', flavor: readFlavor('shadow'), size: {width: 500, height: 'auto'},
                code: `import {app, BaseComponent, readFlavor} from '@onejs-dev/core';
import {View, Text, Input} from '@onejs-dev/components';

const H1Html = BaseComponent('H1Html', true, 'h1');
const InputHtml = BaseComponent('InputHtml', false, 'input');

const App = () => View({content: {direction: 'column', h: 'center', v: 'center', gap: 10}, self: {expand: 1}})([
    H1Html({id: 'myH1'})('Standard H1'),
    InputHtml({type: 'range', value: 5, min: 1, max: 10})
]);

app({component: App, theme: {preset: 'oneJS'}});`}),
            Input({
                type: 'button', url: '/docs', title: 'Back to Docs',
                flavor: readFlavor('light', 'primaryBackground')
            })
        ]),
        View({            
            animation: {visible: ['fade-in', 'fade-out']}, visible: read('doc') === 'Component',
            content: {h: 'stretch', v: 'top', direction: 'column', gap: 20},
            self: {align: 'center'}, style: readStyle('pageContent')
        })([ 
            Text({flavor: readFlavor('section'), style: readStyle('unitTitle')})('Component'),
            readText('ComponentH1P1')({}),
            readText('input')({}),
            Table({columns: 5, template: '2fr 1fr 1fr 4fr 1fr'})([
                readText('name')(), readText('type')(), readText('mandatory')(), readText('description')(), readText('defaultValue')(),
                Text({code: ['name']})('name'), Text({code: ['String']})('String'), readText('yes')(), readText('ComponentName')(), Text()(''),
                Text({code: ['hasChildren']})('hasChildren'), Text({code: ['Boolean']})('Boolean'), readText('yes')(),readText('ComponentHasChildren')(), Text()(''),
                Text({code: ['ComponentFunction']})('ComponentFunction'), Text({code: ['Component']})('Component'), readText('yes')(),readText('ComponentComponentFunction')(), Text()(''),
            ]),
            readText('output')({}),
            Table({columns: 2, template: '2fr 7fr'})([
                Text()('Type'), Text()('Description'),
                Text({code: ['Component']})('Component'), readText('ComponentOutput')()
            ]),
            readText('example')({}),
            CodeDisplay({
                template: 'oneJS', type: 'both', flavor: readFlavor('shadow'), size: {width: 500, height: 'auto'},
                code: `import {app, Component, readFlavor} from '@onejs-dev/core';
import {View, Text, Input} from '@onejs-dev/components';

const H1Text = Component('H1Text', true, ({color, ...attributes}={}) => structure => {
    const titleStyle = {fontSize: 32, color: color, fontWeight: 'bold'};
    return Text({style: titleStyle, ...attributes})(structure);
});
const RangeInput = Component('RangeInput', false, ({label, ...attributes}={}) => {
    return View({content: {v: 'center', gap: 20}})([
        Text()(label),
        Input({type: 'range', ...attributes})
    ]);
});

const App = () => View({content: {direction: 'column', h: 'center', v: 'center', gap: 10}, self: {expand: 1}})([
    H1Text({color: 'blue'})('Custom Title'),
    RangeInput({label: 'Range Input', value: 5, min: 1, max: 10})
]);

app({component: App, theme: {preset: 'oneJS'}});`}),
            Input({
                type: 'button', url: '/docs', title: 'Back to Docs',
                flavor: readFlavor('light', 'primaryBackground')
            })
        ]),
        View({            
            animation: {visible: ['fade-in', 'fade-out']}, visible: read('doc') === 'app',
            content: {h: 'stretch', v: 'top', direction: 'column', gap: 20},
            self: {align: 'center'}, style: readStyle('pageContent')
        })([ 
            Text({flavor: readFlavor('section'), style: readStyle('unitTitle')})('app'),
            readText('appH1P1')({}),
            readText('input')({}),
            Table({columns: 5, template: '2fr 1fr 1fr 4fr 1fr'})([
                readText('name')(), readText('type')(), readText('mandatory')(), readText('description')(), readText('defaultValue')(),
                Text({code: ['name']})('name'), Text({code: ['String']})('String'), readText('no')(), readText('appName')(), Text()(''),
                Text({code: ['component']})('component'), Text({code: ['Component']})('Component'), readText('yes')(),readText('appComponent')(), Text()(''),
                Text({code: ['state']})('state'), Text({code: ['Object']})('Object'), readText('no')(),readText('appState')(), Text()(''),
                Text({code: ['theme']})('theme'), Text({code: ['String', 'Object']})('String or Object'), readText('no')(),View({content: {direction: 'column'}})([readText('appTheme')(), readText('appThemeList')()]), Text()(''),
                Text({code: ['style']})('style'), Text({code: ['Object']})('Object'), readText('no')(),readText('appStyle')(), Text()(''),
                Text({code: ['font']})('font'), Text({code: ['Object']})('Object'), readText('no')(),readText('appFont')(), Text()(''),
                Text({code: ['firestore']})('firestore'), Text({code: ['Object']})('Object'), readText('no')(),readText('appFirestore')(), Text()(''),
            ]),
            readText('output')({}),
            readText('none')(),
            readText('example')({}),
            CodeDisplay({
                template: 'oneJS', type: 'both', flavor: readFlavor('shadow'), size: {width: 500, height: 'auto'},
                code: `import {app, read, update, readFlavor, readStyle} from '@onejs-dev/core';
import {Text, Input} from '@onejs-dev/components';

app({name: 'myApp', 
    component: () => {
        return [
            Text({flavor: readFlavor('title')})('Hello ' + read('name')),
            Input({style: readStyle('outline'), value: read('name'), onChange: update('name')})
        ]
    }, 
    state: {name: ''}, 
    theme: {
        variables: {textFont: 'monospace'},
        flavors: {title: {textColor: 'blue'}}
    }, 
    style: {outline: {border: '2px solid red'}}, 
});`}),
            Input({
                type: 'button', url: '/docs', title: 'Back to Docs',
                flavor: readFlavor('light', 'primaryBackground')
            })
        ]),
        View({            
            animation: {visible: ['fade-in', 'fade-out']}, visible: read('doc') === 'readFlavor',
            content: {h: 'stretch', v: 'top', direction: 'column', gap: 20},
            self: {align: 'center'}, style: readStyle('pageContent')
        })([ 
            Text({flavor: readFlavor('section'), style: readStyle('unitTitle')})('readFlavor'),
            readText('readFlavorH1P1')({}),
            readText('input')({}),
            Table({columns: 5, template: '2fr 1fr 1fr 4fr 1fr'})([
                readText('name')(), readText('type')(), readText('mandatory')(), readText('description')(), readText('defaultValue')(),
                Text({code: ['flavorId']})('flavorId'), Text({code: ['String']})('String'), readText('yes')(),readText('readFlavorFlavorId')(), Text()(''),
            ]),
            readText('output')({}),
            Table({columns: 2, template: '2fr 7fr'})([
                Text()('Type'), Text()('Description'),
                Text({code: ['Object']})('Object'), readText('readFlavorOutput')()
            ]),
            readText('example')({}),
            CodeDisplay({
                template: 'oneJS', type: 'both', flavor: readFlavor('shadow'), size: {width: 500, height: 500},
                code: `import {app, readFlavor} from '@onejs-dev/core';
import {Text} from '@onejs-dev/components';

const theme = {
    flavors: {
        gradient: {
            textGradient: {
                angle: 45, 
                colors: ['red', 'orange', 'yellow', 'green', 'blue', 'purple']
            },
        },
        bigText: {
            textSize: 50
        }
    }
}; 

const App = () => {
    return Text({
        flavor: readFlavor('gradient', 'bigText'), 
        style: {width: 'fit-content'}
    })('Hello World!');
}

app({component: App, theme: theme});`}),
            Input({
                type: 'button', url: '/docs', title: 'Back to Docs',
                flavor: readFlavor('light', 'primaryBackground')
            })
        ]),
        View({            
            animation: {visible: ['fade-in', 'fade-out']}, visible: read('doc') === 'readStyle',
            content: {h: 'stretch', v: 'top', direction: 'column', gap: 20},
            self: {align: 'center'}, style: readStyle('pageContent')
        })([ 
            Text({flavor: readFlavor('section'), style: readStyle('unitTitle')})('readStyle'),
            readText('readStyleH1P1')({}),
            readText('input')({}),
            Table({columns: 5, template: '2fr 1fr 1fr 4fr 1fr'})([
                readText('name')(), readText('type')(), readText('mandatory')(), readText('description')(), readText('defaultValue')(),
                Text({code: ['flavorId']})('flavorId'), Text({code: ['String']})('String'), readText('yes')(),readText('readStyleStyleId')(), Text()(''),
            ]),
            readText('output')({}),
            Table({columns: 2, template: '2fr 7fr'})([
                Text()('Type'), Text()('Description'),
                Text({code: ['Object']})('Object'), readText('readStyleOutput')()
            ]),
            readText('example')({}),
            CodeDisplay({
                template: 'oneJS', type: 'both', flavor: readFlavor('shadow'), size: {width: 500, height: 'auto'},
                code: `import {app, readStyle} from '@onejs-dev/core';
import {Text} from '@onejs-dev/components';

const style = {
    s1: {border: '1px solid red', color: 'green'},
    s2: {fontSize: 32, color: 'blue'}
};

const App = () => {
    return Text({style: readStyle('s1', 's2')})('Hello World!');
}

app({component: App, style: style});`}),
            Input({
                type: 'button', url: '/docs', title: 'Back to Docs',
                flavor: readFlavor('light', 'primaryBackground')
            })
        ]),
        //To implement this we need to make theme a state variable
//         View({            
//             animation: {visible: ['fade-in', 'fade-out']}, visible: read('doc') === 'updateFlavor',
//             content: {h: 'stretch', v: 'top', direction: 'column', gap: 20},
//             self: {align: 'center'}, style: readStyle('pageContent')
//         })([ 
//             Text({flavor: readFlavor('section'), style: readStyle('unitTitle')})('updateFlavor'),
//             readText('readFlavorH1P1')({}),
//             readText('input')({}),
//             Table({columns: 5, template: '2fr 1fr 1fr 4fr 1fr'})([
//                 readText('name')(), readText('type')(), readText('mandatory')(), readText('description')(), readText('defaultValue')(),
//                 Text({code: ['flavorId']})('flavorId'), Text({code: ['String']})('String'), readText('no')(),readText('readFlavorFlavorId')(), Text()(''),
//             ]),
//             readText('output')({}),
//             Table({columns: 2, template: '2fr 7fr'})([
//                 Text()('Type'), Text()('Description'),
//                 Text({code: ['Component']})('Component'), readText('readFlavorOutput')()
//             ]),
//             readText('example')({}),
//             CodeDisplay({
//                 template: 'oneJS', type: 'both', flavor: readFlavor('shadow'), size: {width: 500, height: 500},
//                 code: `import {app, readFlavor} from '@onejs-dev/core';
// import {Text} from '@onejs-dev/components';

// const theme = {
//     flavors: {
//         gradient: {
//             textGradient: {
//                 angle: 45, 
//                 colors: ['red', 'orange', 'yellow', 'green', 'blue', 'purple']
//             },
//         },
//         bigText: {
//             textSize: 50
//         }
//     }
// }; 

// const App = () => {
//     return Text({
//         flavor: readFlavor('gradient', 'bigText'), 
//         style: {width: 'fit-content'}
//     })('Hello World!');
// }

// app({component: App, theme: theme});`}),
//             Input({
//                 type: 'button', url: '/docs', title: 'Back to Docs',
//                 flavor: readFlavor('light', 'primaryBackground')
//             })
//         ]),
        View({            
            animation: {visible: ['fade-in', 'fade-out']}, visible: read('doc') === 'mergeStyles',
            content: {h: 'stretch', v: 'top', direction: 'column', gap: 20},
            self: {align: 'center'}, style: readStyle('pageContent')
        })([ 
            Text({flavor: readFlavor('section'), style: readStyle('unitTitle')})('mergeStyles'),
            readText('mergeStylesH1P1')({}),
            readText('input')({}),
            Table({columns: 5, template: '2fr 1fr 1fr 4fr 1fr'})([
                readText('name')(), readText('type')(), readText('mandatory')(), readText('description')(), readText('defaultValue')(),
                Text({code: ['styles']})('styles'), Text({code: ['Object']})('Object'), readText('yes')(),readText('mergeStylesStyles')(), Text()(''),
            ]),
            readText('output')({}),
            Table({columns: 2, template: '2fr 7fr'})([
                Text()('Type'), Text()('Description'),
                Text({code: ['Component']})('Component'), readText('mergeStylesOutput')()
            ]),
            readText('example')({}),
            CodeDisplay({
                template: 'oneJS', type: 'both', flavor: readFlavor('shadow'), size: {width: 500, height: 'auto'},
                code: `import {app, mergeStyles, Component} from '@onejs-dev/core';
import {Text} from '@onejs-dev/components';

const externalStyle = {border: '1px solid red', color: 'green'};

const Hello = Component('Hello', false, ({name, ...attributes}={}) => {
    const internalStyle = {fontSize: 32, color: 'blue'};
    attributes['style'] = mergeStyles(internalStyle, attributes['style']);
    return Text(attributes)(\`Hello \${name}!\`);
});

const App = () => {
    return Hello({name: 'oneJS', style: externalStyle});
}

app({component: App, theme: {preset: 'oneJS'}});`}),
            Input({
                type: 'button', url: '/docs', title: 'Back to Docs',
                flavor: readFlavor('light', 'primaryBackground')
            })
        ]),

]