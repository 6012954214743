import {
    app, read, readFlavor, readStyle
} from '@onejs-dev/core';
import {
    View, Icon, Input, Text, HtmlSpan, HtmlA,  readText
} from '@onejs-dev/components';

import {CodeDisplay, Navbar, CodeSandbox} from '@onejs-dev/procomponents';
import {text} from '../assets/texts/text.js';

//Icons
// import {icons} from '@onejs-dev/icons';
// import android from '../assets/icons/android.svg';
// import apple from '../assets/icons/apple.svg';
import browsers from '../assets/icons/browsers.svg';
// import buffer from '../assets/icons/buffer.svg';
import github from '../assets/icons/github.svg';
// import globe from '../assets/icons/globe.svg';
// import oneJS from '../assets/icons/oneJS.svg';
// import rocket from '../assets/icons/rocket.svg';
import send from '../assets/icons/send.svg';

import rocket from '../assets/icons/rocket.svg';
import easel from '../assets/icons/easel.svg';
import book from '../assets/icons/book.svg';
import star from '../assets/icons/star.svg';
import gameController from '../assets/icons/game-controller.svg';
// import star from '../assets/icons/star.svg';
// import devices from '../assets/icons/devices.svg';
// import functionalProgramming from '../assets/icons/functionalProgramming.svg';
// import oneLanguage from '../assets/icons/oneLanguage.svg';
import onesieSpeaking from '../assets/icons/onesieSpeaking.svg';
import onesieReading from '../assets/icons/onesieReading.svg';
import onesieGreeting from '../assets/icons/onesieGreetingAnimated.svg';
import onesieMoney from '../assets/icons/onesieMoney.svg';
import logotype from '../assets/logos/logotype.svg';
// import blob from '../assets/icons/blob2.svg';

import {Learn} from './learn/learn.js';
import {Docs} from './docs/docs.js';
// import {readStyle} from '../../core/core.js';
// import React from 'react';

const state = {
    name: '',
    userInput: '',
    color: '#0077ff',
    fontSize: 100,
    selectedOs: {default: 'web', source: {local: 'selectedOs'}, storage: {local: 'selectedOs'}},
    nativeSelected: false,
    unitModal: false,
    homePage: {source: {url: '/'}},
    startedPage: {source: {url: '/get-started/*'}},
    learnPage: {source: {url: '/learn/*'}},
    unit: {source: {url: '/learn/:'}},
    docsPage: {source: {url: '/docs/*'}},
    doc: {source: {url: '/docs/:'}},
    sponsorPage: {source: {url: '/sponsor'}},
    playgroundPage: {source: {url: '/playground'}},
    checkbox: false,
    currentUrl: {source: {url: '/:'}},
    navbarMenu: false,
    htmlTag: '',
    // htmlTags: [],
    // reactNativeComponents: []
};

const style = {
    title: {
        padding: 10,
        textAlign: 'center',
        fontWeight: 'bold',
        // fontFamily: 'Avenir Next Bold',
        fontSize: '50px',
        lineHeight: 1.3,
        color: '#31315f'
    },
    largeSpaceTop: {
        marginTop: 80
    },
    mediumSpaceTop: {
        marginTop: 50
    },
    smallSpaceTop: {
        marginTop: 20
    },
    tinySpaceTop: {
        marginTop: 10
    },
    smallPadding: {
        paddingInline: 15
    },
    mainContent: {
        paddingTop: 80,
        maxWidth: '100vw',
        overflowX: 'hidden',
        // background: 'linear-gradient(90deg, #b0ebd4, #b0baeb)'
    },
    homeContent: {
        paddingTop: 80,
        width: '90vw',
        maxWidth: '100vw',
        // overflowX: 'visible',
        overflow: 'hidden',
    },
    pageContent: {
        paddingBlock: 80,
        width: '90vw',
        maxWidth: 1000,
    },
    claimBackground: {
        background: 'none',
        position: 'relative',
        '--one-color': '#094DFF',
        // '--one-color': '#aaaaff',
        '&::before': {
            content: '""',
            width: 'min(750px, 90vw)',
            height: 400,
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: '50%',
            zIndex: -1,
            background: 'radial-gradient(var(--one-color), transparent)',
            filter: 'blur(40px)',
            opacity: '40%'
        }
    },
    gradient2: {
        '--one-color': '#35a5ea',
        // '--one-color': '#ffaaaa',
    },
    gradient3: {
        '--one-color': '#61FBD5',
        // '--one-color': '#aaffcc',
    },

    claim: {
        maxWidth: '300px',
        minHeight: '220px',
        padding: 20,
        borderRadius: 15,
        border: '1px solid #fff',
        backgroundColor: 'rgba(255,255,255,0.8)',
        backdropFilter: 'blur(5px)',
        position: 'relative',
    },
    contribute: {
        background: 'linear-gradient(135deg, #380036, #0CBABA)',
        paddingBottom: 80
    },
    unitTitle: {
        marginTop: 50,
        textAlign: 'center'
    },
    centeredText: {
        textAlign: 'center'
    },
    // getStarted: {
    //     height: 300,
    //     width: '95vw',
    //     maxWidth: 1300,
    //     borderRadius: 10,
    //     position: 'relative'
    // },
    blob: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: -1,
        // filter: 'blur(5px)'
    },
    getStartedButton: {
        // fontWeight: 'bold',
        fontSize: 22,
        height: 50,
        transitionDuration: '0.4s',
        boxShadow: 'rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '&:hover': {
            boxShadow: 'rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px'
        }
    },
    getStartedText: {
        cursor: 'pointer',
        transitionDuration: '0.4s',
        opacity: 1,
        ':hover': {
            filter: 'brightness(110%)',
            opacity: 0.8
        },
        ':active': {
            filter: 'brightness(90%)',
        },
    },
    tier: {
        alignSelf: 'stretch',
        marginTop: 50,
        marginBottom: 20,
        padding: 10
    },
    card: {
        // padding: 20,
        width: 300,
        height: 300,
        // width: 220,
        // height: 200,
        overflow: 'hidden',
        backgroundColor: '#f1f1f1',
        transitionDuration: '0.4s',
        '&:hover': {
            boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
        }
    },
    cardTitle: {borderBottom: '1px solid #e9e8ee', backgroundColor: 'white'},
    theOne: {backgroundColor: '#094DFF'},
    pointer: {cursor: 'pointer'},
};

const htmlTags = ['a', 'text', 'input', 'div',];

// console.log(text)
// let result = '';
// Object.keys(text).forEach(textId => {
//     if(textId.indexOf('unit')>-1) {result += `readText('${textId}')({}),\n`;}
// });
// console.log(result);


const App = () => {
    const myTemplate = [
        //Header
        Navbar({
            position: 'top', style: {
                backgroundColor: 'rgba(255,255,255, 0.9)',
                backdropFilter: 'blur(7px)'
            }, items: [
                {url: '/', logo: logotype},
                {url: '/get-started', text: 'Start!', icon: rocket},
                {
                    url: '/learn', text: 'Learn', icon: easel, items: [
                        {url: '/learn/unit0', text: 'Unit 0: Introduction'},
                        {url: '/learn/unit1', text: 'Unit 1: Structure'},
                        {url: '/learn/unit2', text: 'Unit 2: State'},
                        {url: '/learn/unit3', text: 'Unit 3: Style'},
                        {url: '/learn/unit4', text: 'Unit 4: Positioning'},
                        {url: '/learn/unit5', text: 'Unit 5: Custom Components'},
                        {url: '/learn/unit6', text: 'Unit 6: Routing'},
                        {url: '/learn/unit7', text: 'Unit 7: Animations'},
                        {url: '/learn/unit8', text: 'Unit 8: Storage'},
                        // {url: '/learn/unit9', text: 'Unit 9: Texts and Translations'}
                    ]
                },
                {
                    url: '/docs', text: 'Docs', icon: book,
                    // items: [
                    //     {url: '/docs/components', text: 'Components'},
                    //     {url: '/docs/functions', text: 'Functions'},
                    //     {url: '/docs/theming', text: 'Theming'},
                    // ]
                },
                {url: '/sponsor', text: 'Sponsor', icon: star},
                {url: '/playground', text: 'Playground', icon: gameController},
            ],
        }),
        //Home Page
        read('homePage') && View({
            type: 'main', animation: {visible: ['fade-in', 'fade-out']}, visible: read('homePage'),
            content: {h: 'center', v: 'center', wrap: false, direction: 'column', gap: 20},
            self: {align: 'center'}, style: readStyle('homeContent')
            // self: {align: 'stretch'}, style: readStyle('mainContent')
        })([
            //Slogan
            readText('slogan')({style: readStyle('smallSpaceTop', 'centeredText')}),

            //Claims
            // View({content: {wrap: true, h: 'center', v: 'center', gap: 20}, style: {background: 'url(background.png)', backgroundRepeat: 'no-repeat', backgroundSize: '100% 100%'}})([
            View({content: {wrap: true, h: 'center', v: 'center', gap: 20}, style: readStyle('mediumSpaceTop')})([
                View({content: {h: 'center', v: 'center', direction: 'column', gap: 10, wrap: false}, style: readStyle('claimBackground'), animation: {intersect: 'fade-in'}})([
                    // Icon({icon: functionalProgramming, size: 200}),
                    Icon({icon: onesieGreeting, size: 120, style: {position: 'absolute', zIndex: 2, top: -50, right: -10}}),
                    View({content: {h: 'center', v: 'top', direction: 'column', gap: 10}, style: readStyle('claim')})([
                        readText('claimH1')(),
                        readText('claimH1P1')(),
                    ]),
                ]),
                View({content: {h: 'center', v: 'center', direction: 'column', gap: 10, wrap: false}, style: readStyle('claimBackground', 'gradient2')})([
                    // Icon({icon: oneLanguage, size: 200}),
                    View({content: {h: 'center', v: 'top', direction: 'column', gap: 10}, style: readStyle('claim')})([
                        readText('claimH2')(),
                        readText('claimH2P1')(),
                    ]),
                ]),
                View({content: {h: 'center', v: 'center', direction: 'column', gap: 10, wrap: false}, style: readStyle('claimBackground', 'gradient3')})([
                    // Icon({icon: devices, size: 200}),
                    View({content: {h: 'center', v: 'top', direction: 'column', gap: 10}, style: readStyle('claim')})([
                        readText('claimH3')(),
                        readText('claimH3P1')(),
                    ]),
                ]),
            ]),

            //Get Started            
            View({content: {direction: 'column', h: 'center', v: 'center', wrap: true, gap: 20}, style: readStyle('largeSpaceTop')})([
                // View({content: {h: 'center', v: 'center', wrap: true, gap: 20}, flavor: readFlavor('primaryGradient', 'flat'), style: readStyle('getStarted', 'smallSpaceTop')})([
                // Icon({icon: blob, size: 500, style: readStyle('blob')}),
                Input({type: 'button', title: 'Get started', url: '/get-started', flavor: readFlavor('primaryBackground', 'white'), style: readStyle('getStartedButton')}),
                View({onPress: () => {navigator.clipboard.writeText('npx @onejs-dev/create-app');}, style: readStyle('getStartedText')})([
                    Text({flavor: readFlavor('dark'), style: {fontFamily: 'Courier New', padding: 10}})('$ npx @onejs-dev/create-app'),
                    Icon({icon: browsers, flavor: readFlavor('dark')})
                ])
            ]),


            //Code
            View({content: {h: 'center', v: 'center', wrap: true, gap: 20}, style: readStyle('mediumSpaceTop')})([
                Icon({icon: onesieSpeaking, size: 100}),
                Text({flavor: readFlavor('header', 'silverGradient')})('Our best publicity is our own code'),
            ]),
            Text()(['State. Routing. Animations. ', HtmlSpan({flavor: readFlavor('dark'), style: {fontWeight: 'bold'}})('Just 20 lines of code')]),
            CodeDisplay({
                template: 'oneJS', type: 'both', flavor: readFlavor('shadow'), 
                options: {autorun: true},
                code: `import {app, read, update, readFlavor} from '@onejs-dev/core';
import {Text, View, Input} from '@onejs-dev/components';
/* State: Variable declaration  ================================================================= */
const state = {name: 'World', home: {source: {url: '/'}}, about: {source: {url: '/about'}}};
/* App Component: Returns the structure to be rendered ========================================== */
const App = () =>
    View({content: {h: 'stretch', v: 'top', direction: 'column', gap: 50}})([
        View({content: {h: 'center', gap: 10}, self: {expand: 0}})([
            Input({type: 'button', title: 'home', url: '/'}),
            Input({type: 'button', title: 'about', url: '/about'})
        ]),
        View({visible: read('home'), content: {h: 'center', direction: 'column', gap: 20}})([
            Text({flavor: readFlavor('section')})(\`Hello \${read('name')}!\`),
            Input({type: 'text', value: read('name'), onChange: update('name')})
        ]),
        View({visible: read('about'), animation: {visible: ['subtle-in', 'subtle-out']}})([
            Text({style: {textAlign: 'center', width: '100%'}})('Developed with ❤️ by oneJS')
        ])
    ]);
/* App Function: Renders the App Component in the screen ======================================== */
app({component: App, theme: {preset: 'oneJS'}, state: state});`}),

            //Contribute
            // Text({style: headerStyle, flavor: readFlavor('primaryGradient')})('Contribute'),
            // Text({style: [subtleHeaderStyle, smallSpaceTop], flavor: readFlavor('gray')})('Do you want to support us?'),
            // View({content: {h: 'center', v: 'top', direction: 'column', wrap: false}, self: {align: 'stretch'}, flavor: readFlavor('darkGradient', 'flat'), style: readStyle('contribute', 'mediumSpaceTop')})([
            View({content: {h: 'center', v: 'top', direction: 'column', wrap: false}, self: {align: 'stretch'}, flavor: readFlavor('noBorder'), style: readStyle('contribute', 'mediumSpaceTop')})([
                View({content: {h: 'center', v: 'center', wrap: true}, style: readStyle('mediumSpaceTop')})([
                    Icon({icon: onesieReading, size: 120}),
                    Text({flavor: readFlavor('header', 'light'), url: './contribute', style: readStyle('centeredText')})('Leave us your suggestions')
                ]),
                Text({flavor: readFlavor('summary', 'light')})('We review them carefully'),
                View({content: {h: 'center', v: 'center', gap: 20, wrap: true}, style: readStyle('smallSpaceTop', 'smallPadding')})([
                    HtmlA({href: 'mailto:onejs.dev@gmail.com', target: '_blank', style: {textDecoration: 'none'}})(Input({type: 'button', title: 'Suggestions', icon: send, flavor: readFlavor('dark', 'lightBackground'), style: readStyle('getStartedButton')})),
                    HtmlA({href: 'https://github.com/oneJS-dev', target: '_blank', style: {textDecoration: 'none'}})(Input({type: 'button', title: 'Give us a star', icon: github, flavor: readFlavor('dark', 'lightBackground'), style: readStyle('getStartedButton')})),
                ])
            ]),

            //Github star or donation
            //This website has entirely been created with oneJS with no additional libraries. We are glad to hear your suggestions, please drop us a mail 

        ]),

        //Playground
        read('playgroundPage') && View({
            animation: {visible: ['fade-in', 'fade-out']}, visible: read('playgroundPage'),
            content: {h: 'center', v: 'top', direction: 'column'},
            self: {align: 'stretch', expand: 1}, style: readStyle('mainContent')
        })([
            CodeSandbox({
                source: 'https://codesandbox.io/embed/onejs-advanced-wxdkud',
                flavor: readFlavor('shadow'), self: {align: 'stretch', expand: 1},
                height: 'calc(100vh - 83px)'
            }),
        ]),

        //Get Started
        read('startedPage') && View({
            animation: {visible: ['fade-in', 'fade-out']}, visible: read('startedPage'),
            content: {h: 'stretch', v: 'top', direction: 'column', gap: 20},
            self: {align: 'center'}, style: readStyle('pageContent')
        })([
            readText('getStartedH1')({style: readStyle('unitTitle')}),
            readText('getStartedH1P1')({}),
            readText('getStartedS1')({}),
            readText('getStartedS1P1')({}),
            readText('getStartedS2')({}),
            readText('getStartedS2P1')({}),
            CodeDisplay({
                type: 'viewer', flavor: readFlavor('shadow'), theme: 'dark',
                code: 'npx @onejs-dev/create-app'
            }),
            readText('getStartedS2P2')({}),
            readText('getStartedS2P3')({}),
            readText('getStartedS2P4')({}),
            CodeDisplay({
                type: 'viewer', flavor: readFlavor('shadow'), theme: 'dark', code: 'npm start',
                style: {minWidth: 200}
            }),
            readText('getStartedS2P5')({}),
            CodeDisplay({template: 'oneJS', flavor: readFlavor('shadow'), type: 'preview'}),
            readText('getStartedS2P6')({}),
            Input({
                type: 'button', url: '/learn', title: 'Keep Learning',
                flavor: readFlavor('light', 'primaryBackground')
            })
            //A new way of writing react apps. Decision fatigue, we take care of state, routing, theming animations for you
        ]),
        read('learnPage') && Learn(),

        //Docs
        read('docsPage') && Docs(),

        //Sponsor
        read('sponsorPage') && View({
            animation: {visible: ['fade-in', 'fade-out']}, visible: read('sponsorPage'),
            content: {h: 'stretch', v: 'top', direction: 'column', gap: 20},
            self: {align: 'center'}, style: readStyle('pageContent')
        })([
            View({content: {h: 'center', v: 'center', gap: 20}, style: readStyle('smallSpaceTop')})([
                Icon({icon: onesieMoney, size: 100}),
                readText('sponsorH1')(),
            ]),
            readText('sponsorH1P1')({style: readStyle('smallSpaceTop')}),
            readText('sponsorS1')({style: {textAlign: 'center'}}),
            View({content: {wrap: true, h: 'center', gap: 10}, style: {padding: 20}})([
                //Backer             
                View({url: 'https://opencollective.com/onejs', content: {h: 'stretch', v: 'space', direction: 'column'}, flavor: readFlavor('outline', 'lightBackground'), style: readStyle('card', 'pointer')})([
                    View({content: {h: 'center', v: 'center'}, style: readStyle('cardTitle')})(readText('sponsorT1')()),
                    View({content: {h: 'center', v: 'top', direction: 'column'}, self: {expand: 1}})([
                        Text({flavor: readFlavor('subsection', 'primary'), style: readStyle('tinySpaceTop')})('$5/month'),
                        readText('sponsorRewards')({style: readStyle('tinySpaceTop')}),
                        readText('sponsorT1P1')({style: readStyle('smallPadding')})
                    ])
                ]),
                //Ambassador             
                View({url: 'https://opencollective.com/onejs', content: {h: 'stretch', v: 'space', direction: 'column'}, flavor: readFlavor('outline', 'lightBackground'), style: readStyle('card', 'pointer')})([
                    View({content: {h: 'center', v: 'center'}, style: readStyle('cardTitle')})(readText('sponsorT2')()),
                    View({content: {h: 'center', v: 'top', direction: 'column'}, self: {expand: 1}})([
                        Text({flavor: readFlavor('subsection', 'primary'), style: readStyle('tinySpaceTop')})('$50/month'),
                        readText('sponsorRewards')({style: readStyle('tinySpaceTop')}),
                        readText('sponsorT2P1')({style: readStyle('smallPadding')})
                    ])
                ]),
                //Silver             
                View({url: 'https://opencollective.com/onejs', content: {h: 'stretch', v: 'space', direction: 'column'}, flavor: readFlavor('outline', 'lightBackground'), style: readStyle('card', 'pointer')})([
                    View({content: {h: 'center', v: 'center'}, style: readStyle('cardTitle')})(readText('sponsorT3')()),
                    View({content: {h: 'center', v: 'top', direction: 'column'}, self: {expand: 1}})([
                        Text({flavor: readFlavor('subsection', 'primary'), style: readStyle('tinySpaceTop')})('$100/month'),
                        readText('sponsorRewards')({style: readStyle('tinySpaceTop')}),
                        readText('sponsorT3P1')({style: readStyle('smallPadding')})
                    ])
                ]),
                //Gold             
                View({url: 'https://opencollective.com/onejs', content: {h: 'stretch', v: 'space', direction: 'column'}, flavor: readFlavor('outline', 'lightBackground'), style: readStyle('card', 'pointer')})([
                    View({content: {h: 'center', v: 'center'}, style: readStyle('cardTitle')})(readText('sponsorT4')()),
                    View({content: {h: 'center', v: 'top', direction: 'column'}, self: {expand: 1}})([
                        Text({flavor: readFlavor('subsection', 'primary'), style: readStyle('tinySpaceTop')})('$500/month'),
                        readText('sponsorRewards')({style: readStyle('tinySpaceTop')}),
                        readText('sponsorT4P1')({style: readStyle('smallPadding')})
                    ])
                ]),
                //Diamond             
                View({url: 'https://opencollective.com/onejs', content: {h: 'stretch', v: 'space', direction: 'column'}, flavor: readFlavor('outline', 'lightBackground'), style: readStyle('card', 'pointer')})([
                    View({content: {h: 'center', v: 'center'}, style: readStyle('cardTitle')})(readText('sponsorT5')()),
                    View({content: {h: 'center', v: 'top', direction: 'column'}, self: {expand: 1}})([
                        Text({flavor: readFlavor('subsection', 'primary'), style: readStyle('tinySpaceTop')})('$1000/month'),
                        readText('sponsorRewards')({style: readStyle('tinySpaceTop')}),
                        readText('sponsorT5P1')({style: readStyle('smallPadding')})
                    ])
                ]),
                //The one             
                View({url: 'https://opencollective.com/onejs', content: {h: 'stretch', v: 'space', direction: 'column'}, flavor: readFlavor('outline', 'lightBackground'), style: readStyle('card', 'pointer')})([
                    View({content: {h: 'center', v: 'center'}, style: readStyle('theOne')})(readText('sponsorT6')({flavor: readFlavor('section', 'white')})),
                    View({content: {h: 'center', v: 'top', direction: 'column'}, self: {expand: 1}})([
                        Text({flavor: readFlavor('subsection', 'primary'), style: readStyle('tinySpaceTop')})('Greatest contributor'),
                        readText('sponsorRewards')({style: readStyle('tinySpaceTop')}),
                        readText('sponsorT6P1')({style: readStyle('smallPadding')})
                    ])
                ]),
            ]),
            View({content: {h: 'center', v: 'center'}, flavor: readFlavor('darkGradient'), style: {padding: 20}})(
                readText('sponsorThanks')({flavor: readFlavor('bold', 'white')})
            )
        ])
    ];
    return myTemplate;
};

const font = {
    'Avenir Next': require('../assets/fonts/AvenirNext.otf'), //Todo: change this require by a loadFont function
    'Avenir Next Bold': require('../assets/fonts/AvenirNextBold.otf')
};

app({component: App, state: state, style: style, theme: {preset: 'oneJS'}, font: font, text: text});