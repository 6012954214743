import {
    app, BaseComponent, read, update, Component, readFlavor, mergeStyles, matchUrl, media, readTextString, readStyle
} from '@onejs-dev/core';
import {
    Button, HtmlPre, HtmlCode, View, Icon, Modal, Input, Text, HtmlSpan,
    HtmlBr, HtmlA, SwipeCards, HtmlImg, HtmlIFrame, readText
} from '@onejs-dev/components';


import {CodeDisplay, Navbar, CodeSandbox} from '@onejs-dev/procomponents';

import unitCircle from '../../assets/icons/unitCircle.svg';
import resultingGradient from '../../assets/icons/resultingGradient.svg';
import datamodel from '../../assets/icons/datamodel.svg';


export const Learn = () => [ 
        //Unit 0: Introduction
        View({
            animation: {visible: ['fade-in', 'fade-out']}, visible: read('unit') === '' || read('unit') === 'unit0',
            content: {h: 'stretch', v: 'top', direction: 'column', gap: 20},
            self: {align: 'center'}, style: readStyle('pageContent')
        })([
            readText('unit0H1')({style: readStyle('unitTitle')}),
            readText('unit0H1P1')({}),
            readText('unit0S1')({}),
            readText('unit0S1P1')({}),
            readText('unit0S1S1')({}),
            CodeDisplay({
                type: 'viewer', flavor: readFlavor('shadow'),
                code: `function add(x, y) = {
    return x + y;
}`
            }),
            readText('unit0S1S2')({}),
            CodeDisplay({
                type: 'viewer', flavor: readFlavor('shadow'),
                code: `const add = (x, y) => {
    return x + y;
}`
            }),
            readText('unit0S1P2')({}),
            readText('unit0S2')({}),
            readText('unit0S2P1')({}),
            readText('unit0S3')({}),
            readText('unit0S3P1')({}),
            readText('unit0S3S1')({}),
            readText('unit0S3S1P1')({}),
            readText('unit0S3S2')({}),
            readText('unit0S3S2P1')({}),
            readText('unit0S4')({}),
            readText('unit0S4S1')({}),
            readText('unit0S4S1P1')({}),
            readText('unit0S4S2')({}),
            readText('unit0S4S2P2')({}),
            readText('unit0S5')({}),
            readText('unit0S5P1')({}),
            readText('unit0S5P2')({}),
            readText('unit0S5P3')({}),
            readText('unit0S5P4')({}),
            Input({
                type: 'button', url: '/unit1', title: 'Next Unit',
                flavor: readFlavor('light', 'primaryBackground')
            })
        ]),
        //Unit 1: Structure
        View({
            animation: {visible: ['fade-in', 'fade-out']}, visible: read('unit') === 'unit1',
            content: {h: 'stretch', v: 'top', direction: 'column', gap: 20},
            self: {align: 'center'}, style: readStyle('pageContent')
        })([
            readText('unit1H1')({style: readStyle('unitTitle')}),
            readText('unit1H1P1')({}),
            readText('unit1S1')({}),
            readText('unit1S1P1')({}),
            CodeDisplay({
                type: 'viewer', flavor: readFlavor('shadow'), size: {width: 500},
                code: `<!–– HTML Definition ––>
<input type="text" id="myInput" style="color: red" />`
            }),
            CodeDisplay({
                type: 'viewer', flavor: readFlavor('shadow'), size: {width: 500},
                code: `/* oneJS Definition */
Input({type: 'text', id: 'myInput', style: {color: 'red'}});`
            }),
            readText('unit1S2')({}),
            readText('unit1S2P1')({}),
            CodeDisplay({
                type: 'viewer', flavor: readFlavor('shadow'), title: '/index.html', size: {width: 500},
                code: `<!–– HTML Definition ––>
<div id="myView" style="background-color: blue" />
    <p class="text">Hello World!</p>
</div>`
            }),
            CodeDisplay({
                type: 'viewer', flavor: readFlavor('shadow'), size: {width: 500},
                code: `/* oneJS Definition */
View({id: 'myView', style: {backgroundColor: 'blue'}})(
    Text({class: 'text'})('Hello World!')
);`
            }),
            readText('unit1S2P2')({}),
            CodeDisplay({
                type: 'viewer', flavor: readFlavor('shadow'), size: {width: 500},
                code: `/* oneJS Definition */
View({id: 'myView', style: {backgroundColor: 'blue'}})([
    Text({class: 'text'})('Hello World!'),
    Input({type: 'text', id: 'myInput', style: {color: 'red'}})
]);`
            }),
            //TODO: Explain the app function
            //TODO: Explain build for production
            readText('unit1S2P3')({}),
            readText('unit1S3')({}),
            readText('unit1S3P1')({}),            
            readText('unit1S3P3')({}),
            CodeDisplay({
                type: 'viewer', flavor: readFlavor('shadow'), size: {width: 500, height: 'auto'},
                code: `/* Configure App State */
const state = {
    stateVariable1: 'defaultValue1',
    stateVariable2: 'defaultValue2'
}
app({component: App, state: state});
`
            }), 
            readText('unit1S3P4')({}),
            CodeDisplay({
                type: 'viewer', flavor: readFlavor('shadow'), size: {width: 500, height: 'auto'},
                code: `/* Apply oneJS preset theme */
app({component: App, theme: {preset: 'oneJS'}});
`
            }),            
            readText('unit1S3P5')({}),
            CodeDisplay({
                type: 'viewer', flavor: readFlavor('shadow'), size: {width: 500, height: 'auto'},
                code: `/* Define App Style */
const style = {
    mainTitle: {color: 'blue'},
    mainIcon: {color: 'green'}
}
app({component: App, style: style});
`
            }), 
            readText('unit1S3P6')({}),
            CodeDisplay({
                type: 'viewer', flavor: readFlavor('shadow'), size: {width: 500},
                code: `/* Import Font */
const font = {
    'Avenir Next': require('../assets/fonts/AvenirNext.otf'),
    'Avenir Next Bold': require('../assets/fonts/AvenirNextBold.otf')
};
app({component: App, font: font});
`
            }),
            readText('unit1S3P7')({}),
            CodeDisplay({
                type: 'viewer', flavor: readFlavor('shadow'), size: {width: 500},
                code: `/* Configure Firestore */
const firebaseConfig = {
    apiKey: "API_KEY",
    authDomain: "PROJECT_id.firebaseapp.com",
    // The value of "databaseURL" depends on the location of the database
    databaseURL: "https://DATABASE_NAME.firebaseio.com",
    projectId: "PROJECT_id",
    storageBucket: "PROJECT_id.appspot.com",
    messagingSenderId: "SENDER_id",
    appId: "APP_id",
    // For Firebase JavaScript SDK v7.20.0 and later, "measurementId" is an optional field
    measurementId: "G-MEASUREMENT_id",
};
const firebaseApp = initializeApp(firebaseConfig);      
const firestoreDB = getFirestore();
app({component: App, firestore: firestoreDB});
`
            }),
            readText('unit1S3P2')({}),
            CodeDisplay({
                template: 'oneJS', type: 'both', flavor: readFlavor('shadow'), size: {width: 500, height: 'auto'},
                code: `/* Imports */
import {app} from '@onejs-dev/core';
import {View, Text, Input} from '@onejs-dev/components';

/* App Component: Takes no properties and returns structure */
const App = () => {
    return View({id: 'myView'})([
        Text({style: {fontSize: 20}})('Name: '),
        Input({type: 'text', style: {background: 'pink'}})
    ]);
};

/* App Function: Renders the App Component in the screen */
app({component: App, theme: {preset: 'oneJS'}});
`
            }), 
            readText('unit1S4')({}),
            readText('unit1S4P1')({}),
            CodeDisplay({
                template: 'oneJS', type: 'both', flavor: readFlavor('shadow'), size: {width: 500, height: 'auto'},
                code: `/* Imports */
import {app} from '@onejs-dev/core';
import {HtmlDiv, HtmlP, HtmlInput} from '@onejs-dev/components';

/* App Component: Takes no properties and returns structure */
const App = () => {
    return HtmlDiv({id: 'myView'})([
        HtmlP({style: {fontSize: 20}})('Name: '),
        HtmlInput({type: 'text', style: {background: 'pink'}})
    ]);
};

/* App Function: Renders the App Component in the screen */
app({component: App});
`
            }), 
            readText('unit1S4P2')({}),
            readText('unit1S4S1')({}),
            readText('unit1S4S1P1')({}),
            readText('unit1S4S1P2')({}),
            CodeDisplay({
                type: 'viewer', flavor: readFlavor('shadow'), size: {width: 500},
                code: `const initAction = () => {
    alert('Initialized');
}
Input({type: 'text', onInit: initAction});`}),
            readText('unit1S4S1P3')({}),
            CodeDisplay({
                type: 'viewer', flavor: readFlavor('shadow'), size: {width: 500},
                code: `const handleClick = () => alert('Clicked');
const createAction = () => {
    component.addEventListener('click', handleClick);
}
Input({type: 'text', onCreate: createAction});`}),
//             CodeDisplay({
//                 template: 'oneJS', type: 'both', flavor: readFlavor('shadow'), size: {width: 500, height: 'auto'},
//                     code: `/* Imports */
// import {app} from '@onejs-dev/core';
// import {View, Text, Input} from '@onejs-dev/components';

// const clickAction = component => {
//     component.addEventListener('click', () => alert('Clicked'));
// }

// /* App Component: Takes no properties and returns structure */
// const App = () => {
//     return View({id: 'myView'})([
//         Text({style: {fontSize: 20}})('Name: '),
//         Input({type: 'text', onCreate: clickAction})
//     ]);
// };

// /* App Function: Renders the App Component in the screen */
// app({component: App, theme: {preset: 'oneJS'}});
// `
//             }), 
            readText('unit1S4S1P4')({}),
            CodeDisplay({
                type: 'viewer', flavor: readFlavor('shadow'), size: {width: 500},
                code: `const handleClick = () => alert('Clicked');
const destroyAction = () => {
    component.removeEventListener('click', handleClick);
}
Input({type: 'text', onDestroy: destroyAction});`}),
            readText('unit1S4S1P5')({}),
            CodeDisplay({
                template: 'oneJS', type: 'both', flavor: readFlavor('shadow'), size: {width: 500, height: 500},
                code: `/* Imports */
import {app, read, update} from '@onejs-dev/core';
import {View, Text, Input} from '@onejs-dev/components';

/* State Configuration */
const state = {
    inputValue: 'pink'
}; 

/* Property Change Function */
const changeAction = (newValue, component) => {  
    component.style.backgroundColor = newValue;
}

/* App Component: Takes no properties and returns structure */
const App = () => {
    return  Input({
        type: 'text', 
        value: read('inputValue'),
        onChange: update('inputValue'), 
        onPropertyChange: {value: changeAction}
    });
};

/* App Function: Renders the App Component in the screen */
app({component: App, state: state, theme: {preset: 'oneJS'}});
`
            }), 
            readText('unit1S4S2')({}),
            readText('unit1S4S2P1')({}),
            CodeDisplay({
                type: 'viewer', flavor: readFlavor('shadow'), size: {width: 500},
                code: `const inputStyle = {
    backgroundColor: 'pink',
    '&:hover': { //Web only
        backgroundColor: 'yellow'
    }
};
Input({type: 'text', style: inputStyle});`}),
            readText('unit1S4S2P2')({}),
            CodeDisplay({
                type: 'viewer', flavor: readFlavor('shadow'), size: {width: 500},
                code: `const inputStyle = {
    backgroundColor: 'pink'
};
Input({type: 'text', inlineStyle: inputStyle});`}),
            readText('unit1S4S3')({}),
            readText('unit1S4S3P1')({}),
            CodeDisplay({
                type: 'viewer', flavor: readFlavor('shadow'), size: {width: 500},
                code: `Input({type: 'button', title: 'Home Page', url: '/home'});`}),
            readText('unit1S5')({}),
            readText('unit1S5P1')({}),
            readText('unit1S5P2')({}),
            readText('unit1S5P3')({}),
            Input({
                type: 'button', url: '/learn/unit2', title: 'Next Unit: Unit 2',
                flavor: readFlavor('light', 'primaryBackground')
            })
        ]),
        //Unit 2: State
        View({
            animation: {visible: ['fade-in', 'fade-out']}, visible: read('unit') === 'unit2',
            content: {h: 'stretch', v: 'top', direction: 'column', gap: 20},
            self: {align: 'center'}, style: readStyle('pageContent')
        })([
            readText('unit2H1')({style: readStyle('unitTitle')}),
            readText('unit2H1P1')({}),
            CodeDisplay({
                type: 'viewer', flavor: readFlavor('shadow'), size: {width: 500},
                code: `/* Imports */
import {app, read, update} from '@onejs-dev/core';
import {View, Text, Input} from '@onejs-dev/components';

/* App Component: Takes no properties and returns structure */
const App = () => {
    return  View({content: {direction: 'column'}})([
        Text()('User Name: '),
        Input({
            type: 'text', 
            placeholder: 'Name'
        })
    ]);
};

/* App Function: Renders the App Component in the screen */
app({component: App, theme: {preset: 'oneJS'}});`}),
            readText('unit2S1')({}),
            readText('unit2S1P1')({}),
            readText('unit2S1S1')({}),
            readText('unit2S1S1P1')({}),
            View({content: {h: 'center', v: 'center', gap: 10}})([
                CodeDisplay({
                    type: 'viewer', flavor: readFlavor('shadow'), size: {width: 300},
                    code: `/* State Configuration */
const state = {
    userName: {default: 'my name'}
}; `}), 
                CodeDisplay({
                    type: 'viewer', flavor: readFlavor('shadow'), size: {width: 300},
                    code: `/* State Configuration */
const state = {
    userName: 'my name'
}; `}),
            ]),
            readText('unit2S1S2')({}),
            readText('unit2S1S2P1')({}),            
            readText('unit2S1S2P2')({}),
            CodeDisplay({
                type: 'viewer', flavor: readFlavor('shadow'), size: {width: 500},
                code: `/* State Configuration */
const state = {
    userName: {        
        source: {local: 'userName'}, 
        storage: {local: 'userName'}
    }
}; `}),
            readText('unit2S1S2P3')({}),
            CodeDisplay({
                type: 'viewer', flavor: readFlavor('shadow'), size: {width: 500},
                code: `/* State Configuration */
const state = {
    userData: {
        source: {firestore: 'users/id1234'}, 
        storage: {firestore: 'users/id1234'}
    }
}; `}),
            readText('unit2S1S2P4')({}),
            CodeDisplay({
                type: 'viewer', flavor: readFlavor('shadow'), size: {width: 500},
                code: `/* State Configuration */
const state = {
    page: {
        source: {url: 'path/*/:'}, 
        //Actual url = 'path/to/home' --> page = 'home';
        //Actual url = 'other/path --> page = undefined;
    }
}; `}),
            readText('unit2S1P2')({}),
            CodeDisplay({
                type: 'viewer', flavor: readFlavor('shadow'), size: {width: 500},
                code: `/* State Configuration */
const state = {
    userName: {default: 'my name'}
}; 
app({component: App, state: state}); `}),
            readText('unit2S2')({}),
            readText('unit2S2P1')({}),
            CodeDisplay({
                type: 'viewer', flavor: readFlavor('shadow'), size: {width: 500, height: 500},
                code: `/* Imports */
import {app, read} from '@onejs-dev/core';
import {View, Text, Input} from '@onejs-dev/components';

/* State Configuration */
const state = {
    userName: ''
}; 

/* App Component: Takes no properties and returns structure */
const App = () => {
    return  View({content: {direction: 'column'}})([
        Text()('User Name: ' + read('userName')),
        Input({
            type: 'text', 
            placeholder: 'Name',
            value: read('userName'),
        })
    ]);
};

/* App Function: Renders the App Component in the screen */
app({component: App, state: state, theme: {preset: 'oneJS'}});
`
            }), 
            readText('unit2S3')({}),
            readText('unit2S3P1')({}),
            CodeDisplay({
                template: 'oneJS', type: 'both', flavor: readFlavor('shadow'), size: {width: 500, height: 500},
                code: `/* Imports */
import {app, read, update} from '@onejs-dev/core';
import {View, Text, Input} from '@onejs-dev/components';

/* State Configuration */
const state = {
    userName: ''
}; 

/* App Component: Takes no properties and returns structure */
const App = () => {
    return  View({content: {direction: 'column'}})([
        Text()('User Name: ' + read('userName')),
        Input({
            type: 'text', 
            placeholder: 'Name',
            value: read('userName'),
            onChange: update('userName'), 
        })
    ]);
};

/* App Function: Renders the App Component in the screen */
app({component: App, state: state, theme: {preset: 'oneJS'}});
`
            }), 
            Input({
                type: 'button', url: '/learn/unit3', title: 'Next Unit: Unit 3',
                flavor: readFlavor('light', 'primaryBackground')
            })
        ]),
        //Unit 3: Style
        View({
            animation: {visible: ['fade-in', 'fade-out']}, visible: read('unit') === 'unit3',
            content: {h: 'stretch', v: 'top', direction: 'column', gap: 20},
            self: {align: 'center'}, style: readStyle('pageContent')
        })([
            readText('unit3H1')({style: readStyle('unitTitle')}),
            readText('unit3H1P1')({}),
            readText('unit3S1')({}),
            readText('unit3S1P1')({}),
            readText('unit3S1P2')({}),
            readText('unit3S1P3')({}),
            CodeDisplay({
                template: 'oneJS', type: 'both', flavor: readFlavor('shadow'), size: {width: 500},
                code: `/* Imports */
import {app} from '@onejs-dev/core';
import {View, Input} from '@onejs-dev/components';

/* Style */
const button1Style = {color: 'white', backgroundColor: 'blue'};
const button2Style = {backgroundColor: 'red', borderRadius: 30};

/* App Component: Takes no properties and returns structure */
const App = () => {
    return  View()([
        Input({type: 'button', title: 'Button 1', style: button1Style}),
        Input({type: 'button', title: 'Button 2', style: [button1Style, button2Style]}),
        Input({type: 'button', title: 'Button 3', inlineStyle: {color: 'green'}}),
    ]);
};

/* App Function: Renders the App Component in the screen */
app({component: App});
`
            }), 
            readText('unit3S1P4')({}),
            CodeDisplay({
                type: 'viewer', flavor: readFlavor('shadow'), size: {width: 500},
                code: `const style = {
    button1Style: {color: 'white', backgroundColor: 'blue'},
    button2Style: {backgroundColor: 'red', borderRadius: 30}
}

const App = () => {
    return  View()([
        Input({type: 'button', title: 'Button 1', style: readStyle('button1Style')}),
        Input({type: 'button', title: 'Button 2', style: readStyle('button1Style', 'button2Style')}),
    ]);
};

app({component: App, style: style});`}),
            readText('unit3S2')({}),
            readText('unit3S2P1')({}),            
            CodeDisplay({
                type: 'viewer', flavor: readFlavor('shadow'), size: {width: 500},
                code: `const themeVariables = {
    /* The values below show the default values for oneJS preset theme */
    //Color
    backgroundColor: '#ffffff', //The color for the component's background.
    primaryColor: '#094DFF',    //The primary contrast color for the component.
    neutralColor: '#b1b0c5',    //Neutral color that can be used for backgrounds or disabled buttons.
    acceptColor: '#60b33a',     //Color for affirmative actions. Usually green.
    rejectColor: '#ff5100',     //Color for negative actions. Usually red.
    warnColor: '#f7df00',       //Color for warning. Usually yellow.
    lightColor: '#e9e8ee',      //Light color for icons or backgrounds.
    darkColor: '#4c4b66',       //Dark color for icons or backgrounds

    //Text
    textFont: 'Avenir Next',    //Font family for texts.
    textColor: '#666488',       //Font color for texts.
    textSize: 18,               //Font size for texts.
    textWeight: 'normal',       //Font weight for texts.

    //Border
    radius: 10,                 //Border radius to create round corners.
    borderWidth: 1,             //Border width.
    borderStyle: 'solid',       //Border style.
    borderColor: '#e9e8ee',     //Border color.

    //Shadow
    shadow: {elevation: 0},     //The component's elevation creating a shadow. Value from 0 to 20.
};`}),
            readText('unit3S2P2')({}),
            CodeDisplay({
                type: 'viewer', flavor: readFlavor('shadow'), size: {width: 500},
                code: `const themeFlavors = {
    //Shadow
    shadow: {
        shadow: {elevation: 10},
    },
    noShadow: {
        shadow: {elevation: 0},
    },
    //Border
    border: {
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: themeVariables?.borderColor,
    },
    noBorder: {
        borderWidth: 0,
        borderStyle: 'none',
        borderColor: 'transparent',
    },
    flat: {
        shadow: {elevation: 0},
        borderWidth: 0,
        borderColor: 'transparent',
        radius: 0
    },
    //Text     
    bold: {
        textWeight: 'bold'
    },
    normal: {
        textWeight: 'normal'
    },
    title: {
        textWeight: 'bold',
        textColor: themeVariables?.darkColor,
        textSize: typeof themeVariables?.textSize === 'number' ?
            themeVariables?.textSize * 3 : '300%'
    },
    header: {
        textWeight: 'bold',
        textColor: themeVariables?.darkColor,
        textSize: typeof themeVariables?.textSize === 'number' ?
            themeVariables?.textSize * 2 : '200%'
    },
    section: {
        textWeight: 'bold',
        textColor: themeVariables?.darkColor,
        textSize: typeof themeVariables?.textSize === 'number' ?
            Math.round(themeVariables?.textSize * 1.5) : '200%'
    },
    subsection: {
        textWeight: 'bold',
        textColor: themeVariables?.darkColor
    },
    summary: {
        textSize: typeof themeVariables?.textSize === 'number' ?
            Math.round(themeVariables?.textSize * 1.3) : '200%'
    },
    //Color 
    reverse: {
        primaryColor: themeVariables?.backgroundColor,
        textColor: themeVariables?.backgroundColor,
        backgroundColor: themeVariables?.primaryColor,
    },
    selected: {
        primaryColor: themeVariables?.primaryColor,
        textColor: themeVariables?.primaryColor, 
    },
    unselected: {
        primaryColor: themeVariables?.neutralColor,
        textColor: themeVariables?.neutralColor,
    },
    primary: {
        primaryColor: themeVariables?.primaryColor,
        textColor: themeVariables?.primaryColor
    },
    primaryBackground: {
        backgroundColor: themeVariables?.primaryColor,
    },
    white: {
        primaryColor: '#fff',
        textColor: '#fff'
    },
    whiteBackground: {
        backgroundColor: '#fff',
    },
    light: {
        primaryColor: themeVariables?.lightColor,
        textColor: themeVariables?.lightColor
    },
    lightBackground: {
        backgroundColor: themeVariables?.lightColor
    },
    neutral: {
        primaryColor: themeVariables?.neutralColor,
        textColor: themeVariables?.neutralColor
    },
    neutralBackground: {
        backgroundColor: themeVariables?.neutralColor,
    },
    dark: {
        primaryColor: themeVariables?.darkColor,
        textColor: themeVariables?.darkColor
    },
    darkBackground: {
        backgroundColor: themeVariables?.darkBackground,
    },
    accept: {
        primaryColor: themeVariables?.acceptColor,
        textColor: themeVariables?.acceptBackground
    },
    acceptBackground: {
        backgroundColor: themeVariables?.acceptBackground,
    },
    reject: {
        primaryColor: themeVariables?.rejectColor,
        textColor: themeVariables?.rejectColor
    },
    rejectBackground: {
        backgroundColor: themeVariables?.rejectColor,
    },
    warn: {
        primaryColor: themeVariables?.warnColor,
        textColor: themeVariables?.warnColor
    },
    warnBackground: {
        backgroundColor: themeVariables?.warnColor,
    }
};`}),
            readText('unit3S2P3')({}),
            readText('unit3S2P4')({}),
            CodeDisplay({
                type: 'viewer', flavor: readFlavor('shadow'), size: {width: 500},
                code: `const themeVariables = {
    backgroundColor: 'white',
    primaryColor: 'blue',
    neutralColor: 'gray',
    textSize: 18,
    textWeight: 'normal',
    radius: 10,  
    borderWidth: 1,
    borderStyle: 'solid'
    borderColor: 'black',
    shadow: {elevation: 10},
};
const themeFlavors = {
    flat: {
        radius: 0,
        borderWidth: 0,
        borderColor: 'transparent',
        shadow: {elevation: 0}
    },
    title: {
        textSize: 32,
        fontWeight: 'bold'
    }
};
app({component: App, theme: {variables: themeVariables, flavors: themeFlavors}});
`}),
            readText('unit3S3')({}),
            readText('unit3S3P1')({}),
            CodeDisplay({
                template: 'oneJS', type: 'both', flavor: readFlavor('shadow'), size: {width: 500},
                code: `/* Imports */
import {app, readFlavor} from '@onejs-dev/core';
import {View, Input} from '@onejs-dev/components';

/* Theming */
const flavors = {
    button1Flavor: {textColor: 'white', radius: 30, backgroundColor: 'blue'}
};

/* App Component: Takes no properties and returns structure */
const App = () => {
    return  View()([
        Input({type: 'button', title: 'Button 1', flavor: readFlavor('button1Flavor')}),
    ]);
};

/* App Function: Renders the App Component in the screen */
app({component: App, theme: {flavors: flavors}});
`
            }), 
            readText('unit3S3P2')({}),
            CodeDisplay({
                template: 'oneJS', type: 'both', flavor: readFlavor('shadow'), size: {width: 500},
                code: `/* Imports */
import {app, readFlavor} from '@onejs-dev/core';
import {View, Input} from '@onejs-dev/components';

const presetFlavors = [
    //Outline
    'default', 'shadow', 'noShadow', 'border', 'noBorder', 'flat', 
    //Text
    'bold', 'normal', 'title', 'header', 'section', 'subsection', 'summary', 
    //Selection
    'reverse', 'selected', 'unselected', 
    //Colors
    'primary', 'primaryBackground', 'white', 'whiteBackground', 
    'light', 'lightBackground',  'neutral', 'neutralBackground', 
    'dark', 'darkBackground', 'accept', 'acceptBackground',
    'reject', 'rejectBackground', 'warn', 'warnBackground'
];

/* App Component: Takes no properties and returns structure */
const App = () => {
    return  View({content: {wrap: true, gap: 10}})([
        presetFlavors.map(flavor => Input({
            type: 'button', title: flavor, key: flavor, flavor: readFlavor(flavor)
        }))
    ]);
};

/* App Function: Renders the App Component in the screen */
app({component: App, theme: {preset: 'oneJS'}});
`
            }), 
            readText('unit3S4')({}),
            readText('unit3S4P1')({}),
            View({content: {h: 'center', v: 'center', gap: 20}})([
                Icon({icon: unitCircle, size: {width: 200, height: 200}, raised: true, flavor: readFlavor('dark', 'shadow')}),
                Icon({icon: resultingGradient, size: 200, raised: true, flavor: readFlavor('dark', 'shadow')}),
            ]),
            readText('unit3S4P2')({}),
            CodeDisplay({
                template: 'oneJS', type: 'both', flavor: readFlavor('shadow'), size: {width: 500},
                dependencies: {'@onejs-dev/icons': '1.0.1'},
                code: `/* Imports */
import {app, readFlavor} from '@onejs-dev/core';
import {View, Text, Icon} from '@onejs-dev/components';
import {icons} from '@onejs-dev/icons';

/* Flavor Definition */
const themeFlavors = {
    myGradient: {
        textGradient: {angle: 0, colors: ['green', 'blue']},
        primaryGradient: {angle: 45, colors: ['pink', 'red']},
        backgroundGradient: {angle: 90, colors: ['silver', 'white']}
    }
};

/* App Component: Takes no properties and returns structure */
const App = () => {
    return  View({flavor: readFlavor('myGradient')})([
        Text({flavor: readFlavor('myGradient')})('Create beautiful gradients'),
        Icon({icon: icons['heart'], flavor: readFlavor('myGradient')})
    ]);
};

/* App Function: Renders the App Component in the screen */
app({component: App, theme: {flavors: themeFlavors}});`
            }), 
            readText('unit3S4P3')({}),
            CodeDisplay({
                template: 'oneJS', type: 'both', flavor: readFlavor('shadow'), size: {width: 500},
                code: `/* Imports */
import {app, readFlavor} from '@onejs-dev/core';
import {View, Input} from '@onejs-dev/components';

const presetGradients = [
    'primaryGradient', 'whiteGradient',
    'lightGradient', 'neutralGradient', 
    'darkGradient', 'acceptGradient',
    'rejectGradient', 'warnGradient',
];

/* App Component: Takes no properties and returns structure */
const App = () => {
    return  View({content: {wrap: true, gap: 10}})([
        presetGradients.map(flavor => Input({
            type: 'button', title: flavor, key: flavor, flavor: readFlavor(flavor)
        }))
    ]);
};

/* App Function: Renders the App Component in the screen */
app({component: App, theme: {preset: 'oneJS'}});`
            }), 
            readText('unit3S4P4')({}),
            Input({
                type: 'button', url: '/learn/unit4', title: 'Next Unit: Unit 4',
                flavor: readFlavor('light', 'primaryBackground')
            })
        ]),
        //Unit 4: Positioning
        View({
            animation: {visible: ['fade-in', 'fade-out']}, visible: read('unit') === 'unit4',
            content: {h: 'stretch', v: 'top', direction: 'column', gap: 20},
            self: {align: 'center'}, style: readStyle('pageContent')
        })([
            readText('unit4H1')({style: readStyle('unitTitle')}),
            readText('unit4H1P1')({}),
            readText('unit4S1')({}),
            readText('unit4S1P1')({}),
            readText('unit4S1P2')({}),
            readText('unit4S1P3')({}),
            CodeDisplay({
                template: 'oneJS', type: 'both', flavor: readFlavor('shadow'), size: {width: 500},
                dependencies: {'@onejs-dev/icons': '1.0.1'},
                code: `/* Imports */
import {app, read, update, readFlavor, readStyle} from '@onejs-dev/core';
import {View, Input, Text, Icon} from '@onejs-dev/components';
import {icons} from '@onejs-dev/icons';

/* State configuration */
const state = {
    direction: 'row',
    wrap: true,
    h: 'left',
    v: 'top',
    gap: 0
}

/* Input options */
const options = {
    direction: ['row', 'column', 'row-reverse', 'column-reverse'],
    wrap: [true, false],
    h: ['left', 'center', 'right', 'space', 'distribute', 'stretch'],
    v: ['top', 'center', 'bottom', 'space', 'distribute', 'stretch']
}

/* Style object creation */
const style = {
    label: {width: 85, fontWeight: 'bold', textAlign: 'right', paddingRight: 10},
    childrenSize: {width: 50, height: 50},
    oneSize: {width: 35, height: 35},
    containerSize: {width: 230, height: 230},
    inputPanelSize: {width: 430},
    inputSize: {minWidth: 50, width: 115}
}

/* App Component: Takes no properties and returns structure */
const App = () => {
    return  [
        /* Content option panel*/
        Text({flavor: readFlavor('subsection')})('Content Options:'),
        View({flavor: readFlavor('outline'), content: {wrap: true}, style: readStyle('inputPanelSize')})([
            Text({style: readStyle('label')})('direction'), 
            Input({type: 'list', options: options.direction, value: read('direction'), onChange: update('direction'), style: readStyle('inputSize')}),
            Text({style: readStyle('label')})('wrap'), 
            Input({type: 'list', options: options.wrap, value: read('wrap'), onChange: update('wrap'), style: readStyle('inputSize')}),
            Text({style: readStyle('label')})('h'), 
            Input({type: 'list', options: options.h, value: read('h'), onChange: update('h'), style: readStyle('inputSize')}),
            Text({style: readStyle('label')})('v'), 
            Input({type: 'list', options: options.v, value: read('v'), onChange: update('v'), style: readStyle('inputSize')}),
            Text({style: readStyle('label')})('gap: ' + read('gap')), 
            Input({type: 'range', min: 0, max: 20, value: read('gap'), onChange: update('gap'), style: readStyle('inputSize')})
        ]),
        /* View parent component for "content" property*/
        View({
            flavor: readFlavor('lightBackground'), 
            style: readStyle('containerSize'),
            content: {
                direction: read('direction'), 
                wrap: read('wrap') !== 'false' ? true : false, 
                h: read('h'), 
                v: read('v'), 
                gap: Number(read('gap'))
            },
        })([
            View({flavor: readFlavor('darkGradient'), style: readStyle('childrenSize')})(Text({flavor: readFlavor('section', 'light')})('0')),
            View({flavor: readFlavor('primaryGradient'), style: readStyle('oneSize')})(Text({flavor: readFlavor('section', 'light')})('1')),
            View({flavor: readFlavor('darkGradient'), style: readStyle('childrenSize')})(Text({flavor: readFlavor('section', 'light')})('2')),
            View({flavor: readFlavor('darkGradient'), style: readStyle('childrenSize')})(Text({flavor: readFlavor('section', 'light')})('3')),
            View({flavor: readFlavor('darkGradient'), style: readStyle('childrenSize')})(Text({flavor: readFlavor('section', 'light')})('4')),
            View({flavor: readFlavor('darkGradient'), style: readStyle('childrenSize')})(Text({flavor: readFlavor('section', 'light')})('5')),
            View({flavor: readFlavor('darkGradient'), style: readStyle('childrenSize')})(Text({flavor: readFlavor('section', 'light')})('6')),
            View({flavor: readFlavor('darkGradient'), style: readStyle('childrenSize')})(Text({flavor: readFlavor('section', 'light')})('7')),
            View({flavor: readFlavor('darkGradient'), style: readStyle('childrenSize')})(Text({flavor: readFlavor('section', 'light')})('8')),
            View({flavor: readFlavor('darkGradient'), style: readStyle('childrenSize')})(Text({flavor: readFlavor('section', 'light')})('9'))
        ])
    ];
};

/* App Function: Renders the App Component in the screen */
app({component: App, state: state, style: style, theme: {preset: 'oneJS'}});`
            }), 
            readText('unit4S2')({}),
            readText('unit4S2P1')({}),
            readText('unit4S2P2')({}),
            CodeDisplay({
                template: 'oneJS', type: 'both', flavor: readFlavor('shadow'), size: {width: 500},
                dependencies: {'@onejs-dev/icons': '1.0.1'},
                code: `/* Imports */
import {app, read, update, readFlavor, readStyle} from '@onejs-dev/core';
import {View, Input, Text, Icon} from '@onejs-dev/components';
import {icons} from '@onejs-dev/icons';

/* State configuration */
const state = {
    direction: 'row',
    wrap: true,
    h: 'left',
    v: 'top',
    gap: 0,
    align: 'auto',
    expand: 0,
    shrink: 1
}

/* Input options */
const options = {
    direction: ['row', 'column', 'row-reverse', 'column-reverse'],
    wrap: [true, false],
    h: ['left', 'center', 'right', 'space', 'distribute', 'stretch'],
    v: ['top', 'center', 'bottom', 'space', 'distribute', 'stretch'],
    align: ['auto', 'left', 'right', 'top', 'bottom', 'center', 'stretch']
}

/* Style object creation */
const style = {
    label: {width: 85, fontWeight: 'bold', textAlign: 'right', paddingRight: 10},
    childrenSize: {width: 50, height: 50},
    oneSize: {width: 35, height: 35},
    containerSize: {width: 230, height: 230},
    inputPanelSize: {width: 430},
    inputSize: {minWidth: 50, width: 115}
}

/* App Component: Takes no properties and returns structure */
const App = () => {
    return  [
        /* Content option panel*/
        Text({flavor: readFlavor('subsection')})('Content Options:'),
        View({flavor: readFlavor('outline'), content: {wrap: true}, style: readStyle('inputPanelSize')})([
            Text({style: readStyle('label')})('direction'), 
            Input({type: 'list', options: options.direction, value: read('direction'), onChange: update('direction'), style: readStyle('inputSize')}),
            Text({style: readStyle('label')})('wrap'), 
            Input({type: 'list', options: options.wrap, value: read('wrap'), onChange: update('wrap'), style: readStyle('inputSize')}),
            Text({style: readStyle('label')})('h'), 
            Input({type: 'list', options: options.h, value: read('h'), onChange: update('h'), style: readStyle('inputSize')}),
            Text({style: readStyle('label')})('v'), 
            Input({type: 'list', options: options.v, value: read('v'), onChange: update('v'), style: readStyle('inputSize')}),
            Text({style: readStyle('label')})('gap: ' + read('gap')), 
            Input({type: 'range', min: 0, max: 20, value: read('gap'), onChange: update('gap'), style: readStyle('inputSize')})
        ]),
        /* Align option panel */
        Text({flavor: readFlavor('subsection')})('Align Options:'),
        View({flavor: readFlavor('outline'), content: {wrap: true}, style: readStyle('inputPanelSize')})([
            Text({style: readStyle('label')})('expand: '+ read('expand')), 
            Input({type: 'range', min: 0, max: 1, value: read('expand'), onChange: update('expand'), style: readStyle('inputSize')}),
            Text({style: readStyle('label')})('shrink: ' + read('shrink')), 
            Input({type: 'range', min: 0, max: 1, value: read('shrink'), onChange: update('shrink'), style: readStyle('inputSize')}),
            Text({style: readStyle('label')})('align'), 
            Input({type: 'list', options: options.align, value: read('align'), onChange: update('align'), style: readStyle('inputSize')})
        ]),
        /* View parent component for "content" property*/
        View({
            flavor: readFlavor('lightBackground'), 
            style: readStyle('containerSize'),
            content: {
                direction: read('direction'), 
                wrap: read('wrap') !== 'false' ? true : false, 
                h: read('h'), 
                v: read('v'), 
                gap: Number(read('gap'))
            },
        })([
            View({flavor: readFlavor('darkGradient'), style: readStyle('childrenSize')})(Text({flavor: readFlavor('section', 'light')})('0')),
            /* View child component for "self" property */
            View({flavor: readFlavor('primaryGradient'), style: readStyle('oneSize'), 
                self: {
                    align: read('align'),
                    expand: read('expand'),
                    shrink: read('shrink')
                }
            })(Text({flavor: readFlavor('section', 'light')})('1')),
            View({flavor: readFlavor('darkGradient'), style: readStyle('childrenSize')})(Text({flavor: readFlavor('section', 'light')})('2')),
            View({flavor: readFlavor('darkGradient'), style: readStyle('childrenSize')})(Text({flavor: readFlavor('section', 'light')})('3')),
            View({flavor: readFlavor('darkGradient'), style: readStyle('childrenSize')})(Text({flavor: readFlavor('section', 'light')})('4')),
            View({flavor: readFlavor('darkGradient'), style: readStyle('childrenSize')})(Text({flavor: readFlavor('section', 'light')})('5')),
            View({flavor: readFlavor('darkGradient'), style: readStyle('childrenSize')})(Text({flavor: readFlavor('section', 'light')})('6')),
            View({flavor: readFlavor('darkGradient'), style: readStyle('childrenSize')})(Text({flavor: readFlavor('section', 'light')})('7')),
            View({flavor: readFlavor('darkGradient'), style: readStyle('childrenSize')})(Text({flavor: readFlavor('section', 'light')})('8')),
            View({flavor: readFlavor('darkGradient'), style: readStyle('childrenSize')})(Text({flavor: readFlavor('section', 'light')})('9'))
        ])
    ];
};

/* App Function: Renders the App Component in the screen */
app({component: App, state: state, style: style, theme: {preset: 'oneJS'}});`
            }), 
            readText('unit4S3')({}),
            readText('unit4S3P1')({}),
            CodeDisplay({
                template: 'oneJS', type: 'both', flavor: readFlavor('shadow'), size: {width: 500},
                code: `/* Imports */
import {app, read, update, readFlavor} from '@onejs-dev/core';
import {View, Input, Text} from '@onejs-dev/components';

/* State Configuration */
const state = {
    isVisible: true
}; 

/* App Component: Takes no properties and returns structure */
const App = () => {
    return  View({content: {gap: 10}})([
        Input({type: 'switch', value: read('isVisible'), onChange: update('isVisible')}),
        View({
            visible: read('isVisible'), 
            flavor: readFlavor('primaryGradient'),
            style: {padding: 40}
        })(Text({flavor: readFlavor('light')})('Toggle visibility'))
    ]);
};

/* App Function: Renders the App Component in the screen */
app({component: App, state: state, theme: {preset: 'oneJS'}});`
            }),             
            readText('unit4S3P2')({}),
            Input({
                type: 'button', url: '/learn/unit5', title: 'Next Unit: Unit 5',
                flavor: readFlavor('light', 'primaryBackground')
            })
        ]),
        //Unit 5: Custom Components
        View({
            animation: {visible: ['fade-in', 'fade-out']}, visible: read('unit') === 'unit5',            
            content: {h: 'stretch', v: 'top', direction: 'column', gap: 20},
            self: {align: 'center'}, style: readStyle('pageContent')
        })([
            readText('unit5H1')({style: readStyle('unitTitle')}),
            readText('unit5H1P1')({}),
            CodeDisplay({
                type: 'viewer', flavor: readFlavor('shadow'), size: {width: 500}, highlight: [1, 15],
                code: `const checksConfig = {
    minCharacters: {regex: /.{8,}/, text: 'Be at least 8 characters'},
    numbers: {regex: /\\d+/, text: 'At least one number'},
    letters: {regex: /[a-z]/, text: 'At least one letter'},
    capitalLetters: {regex: /[A-Z]/, text: 'At least one capital letter'},
    symbols: {regex: /[!-/:-@[-\`{-~]/, text: 'At least one special character'},
    ...config
}`}),
            readText('unit5H1P2')({}),
            CodeDisplay({
                template: 'oneJS', type: 'preview', flavor: readFlavor('shadow'), size: {width: 500, height: 220},
                code: `/* Imports */
import {app, read, update, Component, readFlavor} from '@onejs-dev/core';
import {View, Input, Text} from '@onejs-dev/components';

/* State Configuration */
const state = {
    password: '',
    isValid: false
}; 

const PasswordValidator = Component('Password', false, ({password, value, onChange=()=>{}, 
    checks=['minCharacters', 'numbers', 'letters'], config={}, flavor=readFlavor('default'), 
    ...attributes}={}) => {
    const checksConfig = {
        minCharacters: {regex: /.{8,}/, text: 'Be at least 8 characters'},
        numbers: {regex: /\\d+/, text: 'At least one number'},
        letters: {regex: /[a-z]/, text: 'At least one letter'},
        capitalLetters: {regex: /[A-Z]/, text: 'At least one capital letter'},
        symbols: {regex: /[!-/:-@[-\`{-~]/, text: 'At least one special character'},
        ...config
    }

    password = password ?? '';
    const failedChecks = checks.filter(check => !password.match(checksConfig[check].regex));
    const isValid = failedChecks.length === 0;  
    if(value !== isValid) onChange(isValid);

    const viewStyle = {
        backgroundColor: 'white',
        borderRadius: flavor?.radius, 
        borderColor: flavor?.borderColor,
        borderStyle: flavor?.borderStyle,
        borderWidth: flavor?.borderWidth,
        padding: 15
    };
    
    return View({content: {direction: 'column'}, style: viewStyle, ...attributes})([
        Text({style: {marginBottom: '5px'}})('Requirements:'),
        failedChecks.map(check => Text({list: 'bullets'})(checksConfig[check].text))
    ]);
});

/* App Component: Takes no properties and returns structure */
const App = () => {
    return  View({content: {direction: 'column', gap: 10}})([
        Input({type: 'password', value: read('password'), onChange: update('password'), placeholder: 'Password'}),
        PasswordValidator({password: read('password'), value: read('isValid'), onChange: update('isValid')}),
        View({visible: read('isValid')})(Input({type: 'button', title: 'Strong password', flavor: readFlavor('accept')}))
    ]);
};

/* App Function: Renders the App Component in the screen */
app({component: App, state: state, theme: {preset: 'oneJS'}});`
            }),
            readText('unit5S1')({}),
            readText('unit5S1P1')({}),
            CodeDisplay({
                template: 'oneJS', type: 'both', flavor: readFlavor('shadow'), size: {width: 500, height: 'auto'}, 
                code: `import {app} from '@onejs-dev/core';
import {View, Text} from '@onejs-dev/components';

/* Custom component WITHOUT structure input */
const Greeting = ({name}={}) => {
    return View()(Text()(\`Hello \${name}!\`));
}

const App = () => Greeting({name: 'oneJS'});

app({component: App, theme: {preset: 'oneJS'}});`
            }),
            readText('unit5S1P2')({}),
            CodeDisplay({
                template: 'oneJS', type: 'both', flavor: readFlavor('shadow'), size: {width: 500, height: 'auto'}, 
                code: `import {app} from '@onejs-dev/core';
import {View, Text} from '@onejs-dev/components';

/* Custom component WITH structure input */
const ColorCard = ({color}={}) => structure => {
    return View({style: {backgroundColor: color, padding: 10}})(structure);
}

const App = () => ColorCard({color: 'pink'})(Text()('Card Text'));

app({component: App, theme: {preset: 'oneJS'}});`
            }),
            readText('unit5S1P3')({}),
            CodeDisplay({
                type: 'viewer', flavor: readFlavor('shadow'), size: {width: 500}, highlight: [1, 15],
                code: `/* Wrapped in 'Component' function
const Greeting = Component('Greeting', false, ({name}={}) => {
    return View()(Text()(\`Hello \${name}!\`));
});
const ColorCard = Component('ColorCard', true, ({color}={}) => structure => {
    return View({style: {backgroundColor: color, padding: 10}})(structure);
});`}),
            readText('unit5S1P4')({}),
            CodeDisplay({
                type: 'viewer', flavor: readFlavor('shadow'), size: {width: 500},
                code: `const PasswordValidator = Component('Password', false, ({password, checks, config={}}={}) => {
    const checksConfig = {
        minCharacters: {regex: /.{8,}/, text: 'Be at least 8 characters'},
        numbers: {regex: /\\d+/, text: 'At least one number'},
        letters: {regex: /[a-z]/, text: 'At least one letter'},
        capitalLetters: {regex: /[A-Z]/, text: 'At least one capital letter'},
        symbols: {regex: /[!-/:-@[-\`{-~]/, text: 'At least one special character'},
        ...config
    }

    password = password ?? '';
    const failedChecks = checks.filter(check => !password.match(checksConfig[check].regex));
    const isValid = failedChecks.length === 0;  

    return View({content: {direction: 'column'}})([
        Text()('Requirements:'),
        failedChecks.map(check => Text({list: 'bullets'})(checksConfig[check].text))
    ]);
});`}),
            readText('unit5S2')({}),
            readText('unit5S2P1')({}),
            CodeDisplay({
                type: 'viewer', flavor: readFlavor('shadow'), size: {width: 500}, highlight: [1, 15],
                code: `const PasswordValidator = Component('Password', false, ({password, checks, config={}, ...attributes}={}) => {
    const checksConfig = {
        minCharacters: {regex: /.{8,}/, text: 'Be at least 8 characters'},
        numbers: {regex: /\\d+/, text: 'At least one number'},
        letters: {regex: /[a-z]/, text: 'At least one letter'},
        capitalLetters: {regex: /[A-Z]/, text: 'At least one capital letter'},
        symbols: {regex: /[!-/:-@[-\`{-~]/, text: 'At least one special character'},
        ...config
    }

    password = password ?? '';
    const failedChecks = checks.filter(check => !password.match(checksConfig[check].regex));
    const isValid = failedChecks.length === 0;  

    return View({content: {direction: 'column'}, ...attributes})([
        Text()('Requirements:'),
        failedChecks.map(check => Text({list: 'bullets'})(checksConfig[check].text))
    ]);
});`}),
            readText('unit5S3')({}),
            readText('unit5S3P1')({}),
            CodeDisplay({
                template: 'oneJS', type: 'both', flavor: readFlavor('shadow'), size: {width: 500}, 
                code: `import {app, Component, readFlavor} from '@onejs-dev/core';
import {View, Text} from '@onejs-dev/components';

const Greeting = Component('Greeting', false, ({name, flavor, ...attributes}={}) => {
    const viewStyle = {
        padding: 10,
        borderRadius: flavor?.radius ?? 0,
        backgroundColor: flavor?.backgroundColor ?? 'transparent'
    };
    const textStyle = {
        color: flavor?.textColor ?? 'black'
    };
    return View({style: viewStyle, ...attributes})(Text({style: textStyle})(\`Hello \${name}!\`));
});

const App = () => View({content: {direction: 'column'}})([
    Greeting({name: 'oneJS'}),
    Greeting({name: 'oneJS', flavor: readFlavor('light', 'primaryBackground')}),
    Greeting({name: 'oneJS', flavor: readFlavor('light', 'rejectBackground')})
]);

app({component: App, theme: {preset: 'oneJS'}});`}),
            readText('unit5S3P2')({}),
            CodeDisplay({
                type: 'viewer', flavor: readFlavor('shadow'), size: {width: 500}, highlight: [2, 17, 18, 19, 20, 21, 22, 23, 24, 27],
                code: `const PasswordValidator = Component('Password', false, ({password, checks, config={}, 
    flavor=readFlavor('default'), ...attributes}={}) => { //By default, use the 'default' flavor
    const checksConfig = {
        minCharacters: {regex: /.{8,}/, text: 'Be at least 8 characters'},
        numbers: {regex: /\\d+/, text: 'At least one number'},
        letters: {regex: /[a-z]/, text: 'At least one letter'},
        capitalLetters: {regex: /[A-Z]/, text: 'At least one capital letter'},
        symbols: {regex: /[!-/:-@[-\`{-~]/, text: 'At least one special character'},
        ...config
    }

    password = password ?? '';
    const failedChecks = checks.filter(check => !password.match(checksConfig[check].regex));
    const isValid = failedChecks.length === 0;  

    /* Create style using flavor */
    const flavorStyle = {
        backgroundColor: 'white',
        borderRadius: flavor?.radius, 
        borderColor: flavor?.borderColor,
        borderStyle: flavor?.borderStyle,
        borderWidth: flavor?.borderWidth,
        padding: 15
    };
    
    /* Apply the flavor style to the view */
    return View({content: {direction: 'column'}, style: flavorStyle, ...attributes})([
        Text({style: {marginBottom: '5px'}})('Requirements:'),
        failedChecks.map(check => Text({list: 'bullets'})(checksConfig[check].text))
    ]);
});`}),
            readText('unit5S4')({}),
            readText('unit5S4P1')({}),
            CodeDisplay({
                template: 'oneJS', type: 'both', flavor: readFlavor('shadow'), size: {width: 500}, 
                code: `import {app, Component, readFlavor, mergeStyles} from '@onejs-dev/core';
import {View, Text} from '@onejs-dev/components';

const Greeting = Component('Greeting', false, ({name, flavor, ...attributes}={}) => {
    const viewStyle = {
        padding: 10,
        borderRadius: flavor?.radius ?? 0,
        backgroundColor: flavor?.backgroundColor ?? 'transparent'
    };
    /* Styles are merged: attributes['style'] has priority over viewStyle */
    attributes['style'] = mergeStyles(viewStyle, attributes['style']);
    /* You can also explicitly describe the style priority as displayed below */
    const textStyle = {
        color: attributes?.style?.color ?? flavor?.textColor ?? 'black'
    };
    return View(attributes)(Text({style: textStyle})(\`Hello \${name}!\`));
});

const App = () => View({content: {direction: 'column'}})([
    Greeting({name: 'oneJS', style: {backgroundColor: 'pink'}}),
    Greeting({name: 'oneJS', style: {color: 'black'}, flavor: readFlavor('light', 'primaryBackground')}),
    Greeting({name: 'oneJS', flavor: readFlavor('light', 'rejectBackground')})
]);

app({component: App, theme: {preset: 'oneJS'}});`}),
            readText('unit5S4P2')({}),
            CodeDisplay({
                type: 'viewer', flavor: readFlavor('shadow'), size: {width: 500}, highlight: [26, 29],
                code: `const PasswordValidator = Component('Password', false, ({password, checks, config={}, 
    flavor=readFlavor('default'), ...attributes}={}) => {
    const checksConfig = {
        minCharacters: {regex: /.{8,}/, text: 'Be at least 8 characters'},
        numbers: {regex: /\\d+/, text: 'At least one number'},
        letters: {regex: /[a-z]/, text: 'At least one letter'},
        capitalLetters: {regex: /[A-Z]/, text: 'At least one capital letter'},
        symbols: {regex: /[!-/:-@[-\`{-~]/, text: 'At least one special character'},
        ...config
    }

    password = password ?? '';
    const failedChecks = checks.filter(check => !password.match(checksConfig[check].regex));
    const isValid = failedChecks.length === 0;  

    const flavorStyle = {
        backgroundColor: 'white',
        borderRadius: flavor?.radius, 
        borderColor: flavor?.borderColor,
        borderStyle: flavor?.borderStyle,
        borderWidth: flavor?.borderWidth,
        padding: 15
    };

    /* Merge the external style with the internal flavor style  */
    attributes['style'] = mergeStyles(flavorStyle, attributes['style']);
    
    /* 'attributes' already contains the style attribute */
    return View({content: {direction: 'column'}, ...attributes})([
        Text({style: {marginBottom: '5px'}})('Requirements:'),
        failedChecks.map(check => Text({list: 'bullets'})(checksConfig[check].text))
    ]);
});`}),
            readText('unit5S5')({}),
            readText('unit5S5P1')({}),
            CodeDisplay({
                template: 'oneJS', type: 'both', flavor: readFlavor('shadow'), size: {width: 500, height: 'auto'}, 
                code: `import {app, Component, read, update} from '@onejs-dev/core';
import {View, Input, Text} from '@onejs-dev/components';

const state = {number: 1};

/* Multiplies the input number times the pressed button */
const Multiplier = Component('Multiplier', false, ({value, onChange}={}) => {
    const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9];
    return View()(numbers.map(number => Input({
        type: 'button', title: number, 
        onClick: () => {onChange(value * number)}
    })));
});

const App = () => View({content: {direction: 'column'}})([
    Text()('Result: ' + read('number')),
    Multiplier({value: read('number'), onChange: update('number')}),
]);

app({component: App, state: state, theme: {preset: 'oneJS'}});`}),
            readText('unit5S5P2')({}),
            CodeDisplay({
                template: 'oneJS', type: 'both', flavor: readFlavor('shadow'), size: {width: 500},
                code: `import {app, read, update, Component, readFlavor, mergeStyles} from '@onejs-dev/core';
import {View, Input, Text} from '@onejs-dev/components';

/* State Configuration */
const state = {
    password: '',
    isValid: false
}; 

const PasswordValidator = Component('Password', false, ({password, checks, config={}, 
    value, onChange=()=>{}, flavor=readFlavor('default'), ...attributes}={}) => {
    const checksConfig = {
        minCharacters: {regex: /.{8,}/, text: 'Be at least 8 characters'},
        numbers: {regex: /\\d+/, text: 'At least one number'},
        letters: {regex: /[a-z]/, text: 'At least one letter'},
        capitalLetters: {regex: /[A-Z]/, text: 'At least one capital letter'},
        symbols: {regex: /[!-/:-@[-\`{-~]/, text: 'At least one special character'},
        ...config
    }

    password = password ?? '';
    const failedChecks = checks.filter(check => !password.match(checksConfig[check].regex));
    const isValid = failedChecks.length === 0;  
    /* Trigger onChange function */
    if(value !== isValid) onChange(isValid);

    const flavorStyle = {
        backgroundColor: 'white',
        borderRadius: flavor?.radius, 
        borderColor: flavor?.borderColor,
        borderStyle: flavor?.borderStyle,
        borderWidth: flavor?.borderWidth,
        padding: 15
    };
    attributes['style'] = mergeStyles(flavorStyle, attributes['style']);
    
    return View({content: {direction: 'column'}, ...attributes})([
        Text({style: {marginBottom: '5px'}})('Requirements:'),
        failedChecks.map(check => Text({list: 'bullets'})(checksConfig[check].text))
    ]);
});

const App = () => {
    return  View({content: {direction: 'column', gap: 10}})([
        Input({type: 'password', value: read('password'), onChange: update('password'), placeholder: 'Password'}),
        /* Bind to state variables */
        PasswordValidator({
            password: read('password'), checks: ['minCharacters', 'numbers', 'letters'],
            value: read('isValid'), onChange: update('isValid')
        }),
        View({visible: read('isValid')})(Input({type: 'button', title: 'Strong password', flavor: readFlavor('accept')}))
    ]);
};

/* App Function: Renders the App Component in the screen */
app({component: App, state: state, theme: {preset: 'oneJS'}});`}),
            readText('unit5S5P3')({}),
            Input({
                type: 'button', url: '/learn/unit6', title: 'Next Unit: Unit 6',
                flavor: readFlavor('light', 'primaryBackground')
            })
        ]),
        //Unit 6: Routing
        View({
            animation: {visible: ['fade-in', 'fade-out']}, visible: read('unit') === 'unit6',            
            content: {h: 'stretch', v: 'top', direction: 'column', gap: 20},
            self: {align: 'center'}, style: readStyle('pageContent')
        })([
            readText('unit6H1')({style: readStyle('unitTitle')}),
            readText('unit6H1P1')({}),
            readText('unit6S1')({}),
            readText('unit6S1P1')({}),
            CodeDisplay({
                template: 'oneJS', type: 'both', flavor: readFlavor('shadow'), size: {width: 500, height: 'auto'},
                options: {showNavigator: true},
                code: `import {app} from '@onejs-dev/core';
import {View, Input} from '@onejs-dev/components';

const App = () => {
    return  View({content: {direction: 'row', gap: 10}})([
        Input({type: 'button', title: 'Home', url: '/'}),
        Input({type: 'button', title: 'Products', url: '/products'})
    ]);
};

/* App Function: Renders the App Component in the screen */
app({component: App, theme: {preset: 'oneJS'}});`}),
            View({flavor: readFlavor('lightBackground'), style: {padding: 10}})(readText('unit6S1P2')({})),
            readText('unit6S2')({}),
            readText('unit6S2P1')({}),
            CodeDisplay({
                type: 'viewer', flavor: readFlavor('shadow'), size: {width: 500},
                code: `View({url: '/products'})([
    /* DO NOT do this: Relative url */
    Input({type: 'button, url: './product1'}),
    /* DO this: Absolute url */
    Input({type: 'button, url: '/products/product1'})
]);`}),
            readText('unit6S3')({}),
            readText('unit6S3P1')({}),
            readText('unit6S3P2')({}),
            CodeDisplay({
                template: 'oneJS', type: 'both', flavor: readFlavor('shadow'), size: {width: 500},
                options: {showNavigator: true},
                code: `import {app, read} from '@onejs-dev/core';
import {View, Input, Text} from '@onejs-dev/components';

const state = {
    homePage: {source: {url: '/'}},
    productsPage: {source: {url: '/products'}}
}

const App = () => {
    return  View({content: {direction: 'column', gap: 10}})([
        View()([
            Input({type: 'button', title: 'Home', url: '/'}),
            Input({type: 'button', title: 'Products', url: '/products'})
        ]),
        View({visible: read('homePage')})(Text()('Home Page')),
        View({visible: read('productsPage')})(Text()('Products Page')),
    ]);
};

/* App Function: Renders the App Component in the screen */
app({component: App, state: state, theme: {preset: 'oneJS'}});`}),
            readText('unit6S3P3')({}),
            readText('unit6S4')({}),
            readText('unit6S4P1')({}),
            //TODO: This example is not working, fix it
//             CodeDisplay({
//                 template: 'oneJS', type: 'both', flavor: readFlavor('shadow'), size: {width: 500},
//                 options: {showNavigator: true},
//                 code: `import {app, read, update} from '@onejs-dev/core';
// import {View, Input, Text} from '@onejs-dev/components';

// const state = {
//     selectedUrl: '',
//     activeUrl: {source: {url: '/<selectedUrl>'}},
// }
// const urls = ['home', 'products', 'contact'];

// const App = () => {
//     return  View({content: {direction: 'column', gap: 10}})([
//         View()([
//             Input({type: 'button', title: 'Home', url: '/home'}),
//             Input({type: 'button', title: 'Products', url: '/products'}),
//             Input({type: 'button', title: 'Contact', url: '/contact'})
//         ]),
//         Input({type: 'list', options: urls, value: read('selectedUrl'), onChange: update('selectedUrl')}),
//         View()(Text()('Selected url matches actual url?: ' + read('activeUrl'))),
//     ]);
// };

// /* App Function: Renders the App Component in the screen */
// app({component: App, state: state, theme: {preset: 'oneJS'}});`}),
            // readText('unit6S4P2')({}),
            CodeDisplay({
                template: 'oneJS', type: 'both', flavor: readFlavor('shadow'), size: {width: 500},
                options: {showNavigator: true},
                code: `import {app, read, readFlavor} from '@onejs-dev/core';
import {View, Input, Text} from '@onejs-dev/components';
/* Variable in path to fetch the product id  */
const state = {productId: {source: {url: '/:'}}};
/* Emulates a 'products' database response */
const products = {
    product1: {name: 'pizza', price: '$10'},
    product2: {name: 'risotto', price: '$15'},
    product3: {name: 'tiramisu', price: '$5'}    
};

const App = () => {
    return [
        Object.keys(products).map(product => Input({type: 'button', title: product, url: '/' + product})),
        read('productId') && View({content: {direction: 'column', gap: 10}, flavor: readFlavor('shadow')})([
            Text()('Id: ' + read('productId')),
            Text()('Name: ' + products[read('productId')].name),
            Text()('Price: ' + products[read('productId')].price),
        ]),
    ];
};

/* App Function: Renders the App Component in the screen */
app({component: App, state: state, theme: {preset: 'oneJS'}});`}),
            readText('unit6S4P3')({}),
            Input({
                type: 'button', url: '/learn/unit7', title: 'Next Unit: Unit 7',
                flavor: readFlavor('light', 'primaryBackground')
            })
        ]),
        //Unit 7: Animations
        View({            
            animation: {visible: ['fade-in', 'fade-out']}, visible: read('unit') === 'unit7',
            content: {h: 'stretch', v: 'top', direction: 'column', gap: 20},
            self: {align: 'center'}, style: readStyle('pageContent')
        })([
            readText('unit7H1')({style: readStyle('unitTitle')}),
            readText('unit7H1P1')({}),
            readText('unit7S1')({}),
            readText('unit7S1P1')({}),
            readText('unit7S1P2')({}),
            readText('unit7S1P3')({}),
            CodeDisplay({
                type: 'viewer', flavor: readFlavor('shadow'), size: {width: 500},
                code: `View({animation: {visible: ['fade-in', 'fade-out']}})(
    Text()('Animated View')
);`}),
            readText('unit7S2')({}),
            readText('unit7S2P1')({}),
            CodeDisplay({
                template: 'oneJS', type: 'both', flavor: readFlavor('shadow'), size: {width: 500, height: 'auto'},
                code: `import {app, read, update, readFlavor} from '@onejs-dev/core';
import {View, Input, Text} from '@onejs-dev/components';

const state = {isVisible: true}; 

const App = () => {
    return  View({content: {gap: 10}})([
        Input({type: 'switch', value: read('isVisible'), onChange: update('isVisible')}),
        View({
            visible: read('isVisible'), 
            flavor: readFlavor('primaryGradient'),
            style: {padding: 40},
            /* Defining the animation */
            animation: {visible: ['fade-in', 'fade-out']}
        })(Text({flavor: readFlavor('light')})('Toggle visibility'))
    ]);
};

app({component: App, state: state, theme: {preset: 'oneJS'}});`}),
            readText('unit7S3')({}),
            readText('unit7S3P1')({}),
            CodeDisplay({
                type: 'viewer', flavor: readFlavor('shadow'), size: {width: 500},
                code: `animation: {customProperty: {
    a: 'fade-in',   //When 'customProperty' is equal to 'a' the animation 'fade-in' is triggered
    b: 'fade-out',  //When 'customProperty' is equal to 'b' the animation 'fade-out' is triggered
    c: 'expand'     //When 'customProperty' is equal to 'c' the animation 'expand' is triggered
}}`}),
            readText('unit7S3P2')({}),
            CodeDisplay({
                template: 'oneJS', type: 'both', flavor: readFlavor('shadow'), size: {width: 500},
                code: `import {app, read, update, readFlavor} from '@onejs-dev/core';
import {View, Input, Text} from '@onejs-dev/components';

const state = {choice: ''}; 
const options = ['a', 'b', 'c'];

const App = () => {
    return  View({content: {gap: 10}})([
        Input({type: 'list', options: options, value: read('choice'), onChange: update('choice')}),
        View({
            /* Property triggering the animations */
            customProperty: read('choice'), 
            flavor: readFlavor('primaryGradient'),
            style: {padding: 40},
            /* Defining the animation */
            animation: {customProperty: {a: 'fade-in', b: 'fade-out', c: 'expand'}}
        })(Text({flavor: readFlavor('light')})('Animated Block'))
    ]);
};

app({component: App, state: state, theme: {preset: 'oneJS'}});`}),
            readText('unit7S4')({}),
            readText('unit7S4P1')({}),
            CodeDisplay({
                template: 'oneJS', type: 'both', flavor: readFlavor('shadow'), size: {width: 500},
                code: `import {app, read, update, readFlavor} from '@onejs-dev/core';
import {View, Input, Text} from '@onejs-dev/components';

const state = {
    inAnimation: 'appear',
    outAnimation: 'disappear',
    isVisible: true
}; 

/* Available preset animations */
const inAnimations = ['apprear', 'subtle-in', 'fade-in', 
    'fade-in-left', 'fade-in-right', 'slide-in-left', 
    'slide-in-right', 'slide-in-up', 'slide-in-down', 
    'expand', 'horizontal-expand', 'vertical-expand'];
const outAnimations = ['disapprear', 'subtle-out', 'fade-out', 
    'fade-out-left', 'fade-out-right', 'slide-out-left', 
    'slide-out-right', 'slide-out-up', 'slide-out-down', 
    'shrink', 'horizontal-shrink', 'vertical-shrink'];

const App = () => {
    return  View({content: {direction: 'column', gap: 10}})([
        Text()('In Animation: '),
        Input({type: 'list', options: inAnimations, value: read('inAnimation'), onChange: update('inAnimation')}),
        Text()('Out Animation: '),
        Input({type: 'list', options: outAnimations, value: read('outAnimation'), onChange: update('outAnimation')}),
        Input({type: 'switch', value: read('isVisible'), onChange: update('isVisible')}),
        View({
            visible: read('isVisible'), 
            flavor: readFlavor('primaryGradient'),
            style: {padding: 40},
            /* Defining the animation */
            animation: {visible: [read('inAnimation'), read('outAnimation')]}
        })(Text({flavor: readFlavor('light')})('Animated Block'))
    ]);
};

app({component: App, state: state, theme: {preset: 'oneJS'}});`}),
            readText('unit7S5')({}),
            readText('unit7S5P1')({}),
            readText('unit7S5P2')({}),
            readText('unit7S5P3')({}),
            CodeDisplay({
                template: 'oneJS', type: 'both', flavor: readFlavor('shadow'), size: {width: 500},
                code: `import {app, read, update, readFlavor} from '@onejs-dev/core';
import {View, Input, Text} from '@onejs-dev/components';

const state = {isVisible: true}; 

/* Custom animation creation */
const inAnimation = {
    keyframes: {transform: ['translate3d(-100%, 0, 0)', 'none'], opacity: [0, 1]},
    options: {duration: 1000, easing: 'ease'}
};
const outAnimation = {
    keyframes: {scale: [1, 0], opacity: [1, 0]},
    options: {duration: 1000, easing: 'ease-out'},
    style: {display: 'none'}
};

const App = () => {
    return  View({content: {gap: 10}})([
        Input({type: 'switch', value: read('isVisible'), onChange: update('isVisible')}),
        View({
            visible: read('isVisible'), 
            flavor: readFlavor('primaryGradient'),
            style: {padding: 40},
            /* Defining the animation */
            animation: {visible: [inAnimation, outAnimation]}
        })(Text({flavor: readFlavor('light')})('Animated Block'))
    ]);
};

app({component: App, state: state, theme: {preset: 'oneJS'}});`}),
            readText('unit7S5P4')({}),
            Input({
                type: 'button', url: '/learn/unit8', title: 'Next Unit: Unit 8',
                flavor: readFlavor('light', 'primaryBackground')
            })
        ]),
        //Unit 8: Storage
        View({
            animation: {visible: ['fade-in', 'fade-out']}, visible: read('unit') === 'unit8',
            content: {h: 'stretch', v: 'top', direction: 'column', gap: 20},
            self: {align: 'center'}, style: readStyle('pageContent')
        })([
            readText('unit8H1')({style: readStyle('unitTitle')}),
            readText('unit8H1P1')({}),
            readText('unit8S1')({}),
            readText('unit8S1P1')({}),
            CodeDisplay({
                type: 'viewer', flavor: readFlavor('shadow'), size: {width: 500},
                code: `const state = {
    language: {
        source: {local: 'myAppLanguage'}, 
        storage: {local: 'myAppLanguage'}
    }
};`}),
            readText('unit8S1P2')({}),
            CodeDisplay({
                template: 'oneJS', type: 'both', flavor: readFlavor('shadow'), size: {width: 500},
                code: `import {app, read, update, readFlavor} from '@onejs-dev/core';
import {View, Input, Text} from '@onejs-dev/components';

const state = {
    language: {
        default: 'English',
        source: {local: 'myAppLanguage'}, 
        storage: {local: 'myAppLanguage'}
    }
}

const languages = ['Spanish', 'English', 'French', 'German'];

const App = () => {
    return  View({content: {direction: 'column', gap: 10}})([
        Input({type: 'list', options: languages, value: read('language'), onChange: update('language')}),
        View()(Text()('Selected Language: ' + read('language')))
    ]);
};

app({component: App, state: state, theme: {preset: 'oneJS'}});`}),
            readText('unit8S1P3')({}),
            readText('unit8S2')({}),
            readText('unit8S2P1')({}),
            readText('unit8S2P2')({}),
            View({flavor: readFlavor('lightBackground'), content: {h: 'center', v: 'center'}})(Icon({icon: datamodel, size: {width: 600}, style: {fontFamily: 'Avenir Next'}})),
            readText('unit8S2P3')({}),
            CodeDisplay({
                type: 'viewer', flavor: readFlavor('shadow'), size: {width: 500},
                code: `import {initializeApp} from 'firebase/app';
import {getFirestore} from 'firebase/firestore';

// TODO: Replace the following with your app's Firebase project configuration
// See: https://firebase.google.com/docs/web/learn-more#config-object
const firebaseConfig = {
    // ...
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);`}),
            readText('unit8S2P4')({}),
            CodeDisplay({
                type: 'viewer', flavor: readFlavor('shadow'), size: {width: 500},
                code: `import {app} from '@onejs-dev/core';
import {initializeApp} from 'firebase/app';
import {getFirestore} from 'firebase/firestore';

/* Cloud Firestore Initialization */
const firebaseConfig = {
    // ...
};
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const state = {
    products:  {source: {firestore: 'products'}, storage: {firestore: 'products'}},
    selectedProductId: {default: 'pizza'},
    product: {
        source: {firestore: 'products/<selectedProductId>'}, 
        storage: {firestore: 'products/<selectedProductId>'}
    }
}

const App = () => {
    // ...
};

app({component: App, firestore: db, state: state, theme: {preset: 'oneJS'}});`}),
            readText('unit8S2P5')({}),
            readText('unit8S3')({}),
            readText('unit8S3P1')({}),
            CodeDisplay({
                type: 'viewer', flavor: readFlavor('shadow'), size: {width: 500},
                code: `import {app} from '@onejs-dev/core';

const state = {
    products:  {source: {indexedDB: 'products'}, storage: {indexedDB: 'products'}},
    selectedProductId: {default: 'pizza'},
    product: {
        source: {indexedDB: 'products/<selectedProductId>'}, 
        storage: {indexedDB: 'products/<selectedProductId>'}
    }
}

const App = () => {
    // ...
};

app({component: App, state: state, theme: {preset: 'oneJS'}});`}),
            readText('unit8S4')({}),
            readText('unit8S4P1')({}),
            CodeDisplay({
                type: 'viewer', flavor: readFlavor('shadow'), size: {width: 500}, highlight: [9],
                code: `import {app, read, update} from '@onejs-dev/core';
import {Input} from '@onejs-dev/components';

const state = {
    name: ''
}

const App = () => [
    Input({value: read('name'), onChange: update('name'), placeholder: 'Name'}),
];

app({component: App, state: state, theme: {preset: 'oneJS'}});`}),
            readText('unit8S4P2')({}),
//             CodeDisplay({
//                 template: 'oneJS', type: 'both', flavor: readFlavor('shadow'), size: {width: 500},
//                 code: `import {app, read, update} from '@onejs-dev/core';
// import {Input, Text} from '@onejs-dev/components';

// const state = {
//     stateId:  {default: [
//         {id: 'documentId', name: 'Default value'},
//         {id: 'otherDocumentId', name: 'Other default value'}
//     ]},
//     name: ''
// }

// const App = () => [
//     Input({value: read('name'), onChange: update('name'), placeholder: 'Name'}),
//     Input({type: 'button', title: 'Save', onClick: () => {
//         update('stateId', 'documentId')({...read('stateId', 'documentId'), name: read('name')});
//     }}),
//     Text()('stateId/documentId/name: ' + read('stateId', 'documentId')?.name)
// ];

// app({component: App, state: state, theme: {preset: 'oneJS'}});`}),
                CodeDisplay({
                    type: 'viewer', flavor: readFlavor('shadow'), size: {width: 500}, highlight: [15],
                    code: `import {app, read, update} from '@onejs-dev/core';
import {Input, Text} from '@onejs-dev/components';

const state = {
    stateId:  {default: [
        {id: 'documentId', name: 'Default value'},
        {id: 'otherDocumentId', name: 'Other default value'}
    ]},
    name: ''
}

const App = () => [
    Input({value: read('name'), onChange: update('name'), placeholder: 'Name'}),
    Input({type: 'button', title: 'Save', onClick: () => {
        update('stateId', 'documentId')({...read('stateId', 'documentId'), name: read('name')});
    }})
];

app({component: App, state: state, theme: {preset: 'oneJS'}});`}),
            readText('unit8S4P3')({}),
            CodeDisplay({
                type: 'viewer', flavor: readFlavor('shadow'), size: {width: 500},  highlight: [15],
                code: `import {app, read, update} from '@onejs-dev/core';
import {Input} from '@onejs-dev/components';

const state = {
    stateId:  {default: [
        {id: 'documentId', name: 'Default value'},
        {id: 'otherDocumentId', name: 'Other default value'}
    ]},
    name: ''
}

const App = () => [
    Input({value: read('name'), onChange: update('name'), placeholder: 'Name'}),
    Input({type: 'button', title: 'Save', onClick: () => {
        add('stateId')({name: read('name')});
    }})
];

app({component: App, state: state, theme: {preset: 'oneJS'}});`}),            
            readText('unit8S4P4')({}),
            CodeDisplay({
                type: 'viewer', flavor: readFlavor('shadow'), size: {width: 500},  highlight: [15],
                code: `import {app, read, update} from '@onejs-dev/core';
import {Input} from '@onejs-dev/components';

const state = {
    stateId:  {default: [
        {id: 'documentId', name: 'Default value'},
        {id: 'otherDocumentId', name: 'Other default value'}
    ]},
    name: ''
}

const App = () => [
    Input({value: read('name'), onChange: update('name'), placeholder: 'Name'}),
    Input({type: 'button', title: 'Remove', onClick: () => {
        remove('stateId', 'documentId');
    }})
];

app({component: App, state: state, theme: {preset: 'oneJS'}});`}),           
            readText('unit8S4P5')({}),
            CodeDisplay({
                template: 'oneJS', type: 'both', flavor: readFlavor('shadow'), size: {width: 500},
                code: `import {app, read, add, update, remove, readFlavor} from '@onejs-dev/core';
import {View, Input, Text} from '@onejs-dev/components';

const state = {
    todo: '',
    todos: {source: {indexedDB: 'todos'}, storage: {indexedDB: 'todos'}}    
};
const saveTodo = () => {add('todos')({name: read('todo'), done: false});update('todo')('');}
const updateTodo = todo => () => {update('todos', todo?.id)({...todo, done: !todo.done});}  
const removeTodo = todo => () => {remove('todos', todo?.id);}  

const App = () => {
    return  View({content: {direction: 'column', gap: 10}})([
        Text({flavor: readFlavor('section')})('Write your TODOs:'),
        View()([
            Input({value: read('todo'), onChange: update('todo')}),
            Input({type: 'button', title: 'Save', onClick: saveTodo, flavor: readFlavor('light','primaryBackground')})
        ]),
        read('todos')?.length > 0 && read('todos').map(todo =>  View({content: {gap: 10}})([
            Input({type: 'button', title: 'Delete', onClick: saveTodo, onPress: removeTodo(todo), flavor: readFlavor('light','rejectBackground')}),
            Input({type: 'checkbox', title: todo.name, value: todo.done, onChange: updateTodo(todo)})          
        ]))
    ]);
};

app({component: App, state: state, theme: {preset: 'oneJS'}});`}),
            readText('unit8S4P6')({}),
            Input({
                type: 'button', url: '/docs', title: 'Visit the documentation',
                flavor: readFlavor('light', 'primaryBackground')
            })
        ])
        ];